var render = function render(){var _vm=this,_c=_vm._self._c;return _c('client-only',[_c('div',[_c('Portal',{attrs:{"to":"button_dropdown"}},[_c('div',{ref:"dropdown",staticClass:"fixed bg-gray-100 px-2 md:px-6 py-2 md:py-[18px] -md:w-[calc(100%_-_24px)] md:min-w-[402px] rounded-[10px] md:rounded-[30px]",class:{
          ['!bg-white']: _vm.themeLight,
          ['!min-w-0 !p-[9px] !rounded-lg']: true,
          ['-md:mt-[12px]']: !_vm.small,
        },staticStyle:{"background-color":"#363838"}},[_c('Scroll',{attrs:{"axis":"y","theme":_vm.themeLight ? 'white' : 'dark'}},[_c('div',{staticStyle:{"overflow":"visible"}},[(_vm.label)?_c('div',{staticClass:"text-white py-2 mx-3 border-b border-gray-300 mb-2"},[_vm._v("\n              "+_vm._s(_vm.label)+"\n            ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.items),function(item,i){return [_c(_vm.itemIs,_vm._b({key:`${item.text}_${i}`,tag:"component",staticClass:"rounded-[10px] hover:cursor-pointer hover:bg-gray-300 flex",class:{
                  ['hover:!bg-gray-600']: _vm.themeLight,
                },on:{"click":function($event){return _vm.onSelect(i)}}},'component',_vm.getItemAttr(item),false),[(item.href)?_c('a',{staticClass:"w-full p-2 md:p-[15px] text-white !py-2 !px-3",class:{ ['!text-gray-100']: _vm.themeLight, ['uppercase']: !_vm.themeLight },attrs:{"href":item.href,"target":"_blank"}},[_vm._v("\n                  "+_vm._s(item.text)+"\n                ")]):(item.to)?_c('nuxt-link',{staticClass:"w-full p-2 md:p-[15px] text-white !py-2 !px-3",class:{ ['!text-gray-100']: _vm.themeLight, ['uppercase']: !_vm.themeLight },attrs:{"to":item.to}},[_vm._v("\n                  "+_vm._s(item.text)+"\n                ")]):_c('span',{staticClass:"w-full p-2 md:p-[15px] text-white !py-2 !px-3",class:{ ['!text-gray-100']: _vm.themeLight, ['uppercase']: !_vm.themeLight }},[_vm._v("\n                  "+_vm._s(item.text)+"\n                ")])],1)]})],2)])],1)]),_vm._v(" "),_c('Backdrop',{on:{"close":_vm.close}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }