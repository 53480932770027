var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden",class:{ [_vm.classWrap]: true, '!mt-[-2vw]': _vm.tag === 'h1', '!mt-[-1.8vw]': _vm.tag === 'h2' }},[_c(_vm.tag,{tag:"component",staticClass:"overflow-hidden relative origin-top-left pr-[0.08em] will-change-transform",class:{
      [_vm.animationName]: true,
      [`animation-delay-${_vm.delay}`]: true,
      [_vm.className]: true,
      '!pt-[2vw]': _vm.tag === 'h1',
      '!pt-[1.8vw]': _vm.tag === 'h2',
    },attrs:{"data-scroll":"","data-scroll-offset":_vm.offset,"data-scroll-class":`${_vm.animationName}-call`}},[(_vm.title)?_c('span',{staticClass:"print:!relative print:!top-[-15px]",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('span',[_vm._t("default")],2),_vm._v(" "),(_vm.sup)?_c('sup',{staticClass:"tracking-tight md:tracking-tighter text-[12px] md:text-lg",class:{ [_vm.classNameSup]: true },domProps:{"innerHTML":_vm._s(_vm.sup)}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }