const c = {
  // главная
  MAIN: locale => ({
    title: locale === 'ru' ? 'Главная' : 'Main',
    to: '/',
  }),

  // клубы
  CLUBS: locale => ({
    title: locale === 'ru' ? 'Клубы' : 'Clubs',
    to: '/clubs',
  }),
  CLUB: (locale, { clubTitle, clubSlug }) => ({
    title: clubTitle,
    to: `/${clubSlug}`,
  }),

  // тренеры
  TRAINERS: locale => ({
    title: locale === 'ru' ? 'Тренеры' : 'Trainers',
    to: null,
  }),

  // фитнес
  FITNESS: locale => ({
    title: locale === 'ru' ? 'Фитнес' : 'Fitness',
    to: null,
  }),
  PERSONAL_TRAINING: locale => ({
    title: locale === 'ru' ? 'Персональный тренинг' : 'Personal training',
    to: '/fitness/personal-training',
  }),
  GROUP_TRAINING: locale => ({
    title: locale === 'ru' ? 'Групповой тренинг' : 'Group training',
    to: '/fitness/group-training',
  }),
  MINI_GROUPS: locale => ({
    title: locale === 'ru' ? 'Мини-группы' : 'Mini-groups',
    to: '/fitness/mini-group',
  }),
  FITNESS_TESTING: locale => ({
    title: locale === 'ru' ? 'Фитнес-тестирование' : 'Fitness testing',
    to: '/fitness/testing',
  }),
  KIDS_CLUBS: locale => ({
    title: locale === 'ru' ? 'Детские клубы' : 'Kids club',
    to: '/fitness/kids-clubs',
  }),
  MYZONE: locale => ({
    title: 'MYZONE®',
    to: '/fitness/myzone',
  }),

  // о нас
  ABOUT_US: locale => ({
    title: locale === 'ru' ? 'О нас' : 'About',
    to: null,
  }),
  CAREER: locale => ({
    title: locale === 'ru' ? 'Карьера' : 'Career',
    to: '/career',
  }),
  CONTACT: locale => ({
    title: locale === 'ru' ? 'Контакты' : 'Contacts',
    to: '/contacts',
  }),
  ABOUT_CONCEPT: locale => ({
    title: locale === 'ru' ? 'Концепция Encore' : 'The Encore concept',
    to: '/about',
  }),
  FRANCHISE: locale => ({
    title: locale === 'ru' ? 'Франшиза' : 'Open a fitness club',
    to: '/franchise',
  }),

  // spa
  SPA: locale => ({
    title: locale === 'ru' ? 'SPA' : 'SPA',
    to: '/spa',
  }),
  SPA_CLUB: (locale, { spaTitle, spaSlug }) => ({
    title: spaTitle,
    to: `/${spaSlug}`,
  }),

  // расписание
  TIMETABLE: locale => ({
    title: locale === 'ru' ? 'Расписание' : 'Timetable',
    to: null,
  }),

  // оплата
  PAYMENT_METHODS: locale => ({
    title: locale === 'ru' ? 'Способы оплаты' : 'Payment options',
    to: '/payment',
  }),

  // оплата
  PRIVACY_POLICY: locale => ({
    title: locale === 'ru' ? 'Персональные данные' : 'Privacy policy',
    to: '/privacy-policy',
  }),
}

export default (ctx, inject) => {
  const build =
    (...crumbs) =>
    customize => {
      return crumbs.reduce((acc, crumb) => {
        const link = crumb(ctx.i18n.locale, customize)
        if (link.to) {
          const filtered = acc.filter(c => c.to)
          if (filtered.length > 0) {
            const last = filtered[filtered.length - 1]
            link.to = `${last.to}${link.to}`.replace(/\/+/, '/')
          }
        }
        return acc.concat(link)
      }, [])
    }

  const breadcrumbs = {
    clubs: build(c.MAIN, c.CLUBS),
    club: build(c.MAIN, c.CLUBS, c.CLUB),
    spa: build(c.MAIN, c.SPA),
    spaClub: build(c.MAIN, c.SPA, c.SPA_CLUB),
    timetable: build(c.MAIN, c.TIMETABLE),
    trainers: build(c.MAIN, c.TRAINERS),
    payment: build(c.MAIN, c.PAYMENT_METHODS),
    aboutConcept: build(c.MAIN, c.ABOUT_US, c.ABOUT_CONCEPT),
    career: build(c.MAIN, c.ABOUT_US, c.CAREER),
    franchise: build(c.MAIN, c.ABOUT_US, c.FRANCHISE),
    contacts: build(c.MAIN, c.CONTACT),
    personalTraining: build(c.MAIN, c.FITNESS, c.PERSONAL_TRAINING),
    groupTraining: build(c.MAIN, c.FITNESS, c.GROUP_TRAINING),
    miniGroup: build(c.MAIN, c.FITNESS, c.MINI_GROUPS),
    fitnessTesting: build(c.MAIN, c.FITNESS, c.FITNESS_TESTING),
    kidsClubs: build(c.MAIN, c.FITNESS, c.KIDS_CLUBS),
    myzone: build(c.MAIN, c.FITNESS, c.MYZONE),
    privacyPolicy: build(c.MAIN, c.PRIVACY_POLICY),
  }

  inject('breadcrumbs', breadcrumbs)
}
