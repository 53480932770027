export const state = () => ({
  hasPreloadScreen: true,
})

export const getters = {
  hasPreloadScreen(state) {
    return state.hasPreloadScreen
  },
}

export const mutations = {
  hidePreloadScreen(state) {
    state.hasPreloadScreen = false
  },
}

export const actions = {
  hidePreloadScreen({ commit }) {
    commit('hidePreloadScreen')
  },
}
