let instance = null

export const state = () => ({
  blocked: false,
  customer: null,
})

export const getters = {
  isBlocked(state) {
    return state.blocked
  },
}

export const mutations = {
  block(state, customer) {
    if (sameCustomerOrMoreImportant(customer, state.customer)) {
      state.customer = customer
    }
    state.blocked = true
  },
  unblock(state, customer) {
    if (sameCustomerOrMoreImportant(customer, state.customer)) {
      state.blocked = false
      state.customer = null
    }
  },
}

const customers = ['custom-scroll', 'menu', 'routing']
const sameCustomerOrMoreImportant = (toDiff, current) => {
  return customers.indexOf(toDiff) >= customers.indexOf(current)
}

export const actions = {
  block({ state, commit }, customer) {
    if (process.client && !state.blocked) {
      document.body.style.overflow = 'hidden'
      instance && instance.stop()
    }
    commit('block', customer)
  },
  unblock({ state, commit }, customer) {
    commit('unblock', customer)
    if (process.client && !state.blocked) {
      document.body.style.overflow = ''
      instance && instance.start()
    }
  },
  setInstance(_, inst) {
    instance = inst
  },
}
