<template>
  <div class="overflow-hidden" :class="{ [classWrap]: true, '!mt-[-2vw]': tag === 'h1', '!mt-[-1.8vw]': tag === 'h2' }">
    <component
      :is="tag"
      data-scroll
      :data-scroll-offset="offset"
      :data-scroll-class="`${animationName}-call`"
      class="overflow-hidden relative origin-top-left pr-[0.08em] will-change-transform"
      :class="{
        [animationName]: true,
        [`animation-delay-${delay}`]: true,
        [className]: true,
        '!pt-[2vw]': tag === 'h1',
        '!pt-[1.8vw]': tag === 'h2',
      }"
    >
      <span v-if="title" class="print:!relative print:!top-[-15px]" v-html="title" />
      <span><slot /></span>
      <sup
        v-if="sup"
        class="tracking-tight md:tracking-tighter text-[12px] md:text-lg"
        :class="{ [classNameSup]: true }"
        v-html="sup"
      />
    </component>
  </div>
</template>

<script>
export default {
  name: 'AtomTitle',
  builder: {
    component: 'title',
  },
  props: {
    tag: {
      type: String,
      default: 'h1',
    },
    title: {
      type: String,
      default: '',
    },
    classWrap: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    sup: {
      type: String,
      default: '',
    },
    classNameSup: {
      type: String,
      default: '',
    },
    animation: {
      type: String,
      default: 'none',
    },
    delay: {
      type: String,
      default: '0',
    },
    context: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      offset: '30%,30%',
    }
  },
  computed: {
    animationName() {
      return this.context.titleAnimation || this.animation || ''
    },
  },
  mounted() {
    let offset = Math.floor((window.innerWidth / window.innerHeight) * 20)
    offset = Math.min(offset, 30)
    this.offset = `${offset}%,${offset}%`
  },
}
</script>

<style lang="postcss" scoped>
h1 {
  @apply uppercase pr-[0.08em] py-[0.08em] my-[-0.08em] ml-[-0.04em];
}

h2 {
  @apply py-[0.07em] my-[-0.07em];
}

h1,
h2,
big {
  sup {
    @apply top-[6px] md:top-0 pr-[0.08em] left-1 md:left-3 align-top;
    ::v-deep svg {
      @apply inline-block;
    }
  }
}

big {
  @apply text-[18vw] md:text-[15.694vw] ml-[-0.04em];
  line-height: 85%;
  letter-spacing: -0.09em;
  /* padding: 0 0.09em; */

  sup {
    @apply text-xl;
  }
}

.animation-delay-0 {
  animation-delay: 0;
}
.animation-delay-100 {
  animation-delay: 0.1s;
}
.animation-delay-200 {
  animation-delay: 0.2s;
}
.animation-delay-300 {
  animation-delay: 0.3s;
}
.animation-delay-400 {
  animation-delay: 0.4s;
}
.animation-delay-500 {
  animation-delay: 0.5s;
}

.rotation {
  transform: rotate(5deg) translateY(95%);
}
.rotation-call {
  animation-name: rotation;
  animation-duration: 0.6s;
  animation-timing-function: var(--cubic-bezier);
  animation-fill-mode: forwards;
}

@keyframes rotation {
  from {
    transform: rotate(5deg) translateY(95%);
  }
  to {
    transform: rotate(0deg) translateY(0);
  }
}

.fade {
  transform: translateY(20%);
  opacity: 0.2;
}

.fade-call {
  animation-name: fade;
  animation-duration: 0.6s;
  animation-timing-function: var(--cubic-bezier);
  animation-fill-mode: forwards;
}

@keyframes fade {
  from {
    transform: translateY(20%);
    opacity: 0.2;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
