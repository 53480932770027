<template>
  <section class="bg-gray-100 md:border-l border-l-gray-300">
    <div class="container">
      <div class="relative pt-4 pb-5 md:py-32 justify-center items-center border-b">
        <AtomImage
          class="w-full aspect-[296/62] md:aspect-auto !bg-contain h-full md:absolute top-0 object-contain object-center"
          src="footer/logo.svg"
        />
        <div class="text-center border-t md:border-0 mt-3 md:mt-0 pt-3 md:pt-0 border-gray-300 z-30 relative">
          <AtomTitle tag="h3" class="text-gray-500 mb-3 md:mb-5" class-name="!text-sm md:!text-lg"
            >С приложением еще удобнее</AtomTitle
          >
          <MoleculeBtnApps />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomTitle from '../../atoms/AtomTitle'
import MoleculeBtnApps from './MoleculeBtnApps'

export default {
  name: 'MoleculeFooterApps',
  components: {
    AtomTitle,
    AtomImage,
    MoleculeBtnApps,
  },
}
</script>
