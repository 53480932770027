<template>
  <client-only>
    <div>
      <Portal to="button_dropdown">
        <div
          ref="dropdown"
          class="fixed bg-gray-100 px-2 md:px-6 py-2 md:py-[18px] -md:w-[calc(100%_-_24px)] md:min-w-[402px] rounded-[10px] md:rounded-[30px]"
          :class="{
            ['!bg-white']: themeLight,
            ['!min-w-0 !p-[9px] !rounded-lg']: true,
            ['-md:mt-[12px]']: !small,
          }"
          style="background-color: #363838"
        >
          <Scroll axis="y" :theme="themeLight ? 'white' : 'dark'">
            <div style="overflow: visible">
              <div v-if="label" class="text-white py-2 mx-3 border-b border-gray-300 mb-2">
                {{ label }}
              </div>
              <template v-for="(item, i) in items">
                <component
                  :is="itemIs"
                  :key="`${item.text}_${i}`"
                  class="rounded-[10px] hover:cursor-pointer hover:bg-gray-300 flex"
                  v-bind="getItemAttr(item)"
                  :class="{
                    ['hover:!bg-gray-600']: themeLight,
                  }"
                  @click="onSelect(i)"
                >
                  <a
                    v-if="item.href"
                    :href="item.href"
                    class="w-full p-2 md:p-[15px] text-white !py-2 !px-3"
                    target="_blank"
                    :class="{ ['!text-gray-100']: themeLight, ['uppercase']: !themeLight }"
                  >
                    {{ item.text }}
                  </a>
                  <nuxt-link
                    v-else-if="item.to"
                    :to="item.to"
                    :class="{ ['!text-gray-100']: themeLight, ['uppercase']: !themeLight }"
                    class="w-full p-2 md:p-[15px] text-white !py-2 !px-3"
                  >
                    {{ item.text }}
                  </nuxt-link>
                  <span
                    v-else
                    :class="{ ['!text-gray-100']: themeLight, ['uppercase']: !themeLight }"
                    class="w-full p-2 md:p-[15px] text-white !py-2 !px-3"
                  >
                    {{ item.text }}
                  </span>
                </component>
              </template>
            </div>
          </Scroll>
        </div>
      </Portal>
      <Backdrop @close="close" />
    </div>
  </client-only>
</template>

<script>
import Scroll from '../templates/layouts/Scroll'
import Backdrop from './layouts/AtomBackdrop'

export default {
  name: 'Dropdown',
  components: {
    Backdrop,
    Scroll,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    getRefRoot: {
      type: Function,
      required: true,
    },
    themeLight: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    dropdownAutoWidth: {
      type: Boolean,
      default: false,
    },
    itemIs: {
      type: String,
      default: 'div',
    },
    offset: {
      type: Number,
      default: 0,
    },
    verticalDirection: {
      type: String,
      default: 'down', // down | up
    },
    horizontalDirection: {
      type: String,
      default: 'center', // center | right
    },
    getItemAttr: {
      type: Function,
      default: () => ({}),
    },
    label: {
      type: String,
      default: null,
    },
  },
  mounted() {
    const y = this.$root.$loco.scroll.instance.scroll.y
    this.$root.$loco.setScroll(0, y)
    this.smartPosition()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    onSelect(i) {
      this.close()
      this.$emit('select', this.items[i])
    },
    close() {
      this.$emit('close')
    },
    smartPosition() {
      if (!this.$refs.dropdown) {
        this.timeout = setTimeout(this.smartPosition, 10)
        return
      }
      const getTop = () => {
        const up = rectRoot.y - rectDropdown.height - this.offset
        const bottom = rectRoot.y + rectRoot.height + this.offset
        if (this.verticalDirection === 'up') {
          return up < 0 ? bottom : up
        }
        const canOpenBottom = bottom + rectDropdown.height < window.innerHeight
        return canOpenBottom ? bottom : up
      }
      const getWidth = () => {
        if (rectRoot.width > rectDropdown.width) return rectRoot.width
        return rectDropdown.width
      }

      const isMobile = window.innerWidth < 960
      const rectRoot = this.getRefRoot().getBoundingClientRect()
      let rectDropdown = this.$refs.dropdown.getBoundingClientRect()
      this.$refs.dropdown.style.width = this.dropdownAutoWidth ? 'auto' : `${getWidth()}px`
      // HACK. Dor DOM width update
      this.$refs.dropdown.style[this.horizontalDirection === 'center' ? 'left' : 'right'] = '0px'
      rectDropdown = this.$refs.dropdown.getBoundingClientRect()
      if (isMobile) {
        this.$refs.dropdown.style.left = '12px'
        this.$refs.dropdown.style.right = '12px'
      } else if (this.horizontalDirection === 'center') {
        this.$refs.dropdown.style.left = `${rectRoot.x + rectRoot.width / 2 - rectDropdown.width / 2}px`
      } else if (this.horizontalDirection === 'right') {
        this.$refs.dropdown.style.right = `${window.innerWidth - rectRoot.right}px`
      }
      rectDropdown = this.$refs.dropdown.getBoundingClientRect()
      this.$refs.dropdown.style.top = `${getTop()}px`
    },
  },
}
</script>
