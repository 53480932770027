<template>
  <div class="nav-mobile-item relative w-full flex flex-col self-start md:px-1 lg:px-5">
    <Accordion>
      <AccordionItem :hide="!hasSub" :scroll-into-view="true" class="border-gray-300 !py-0 accordion__item__mobile">
        <template slot="accordion-trigger">
          <div class="nav-head-mobile z-20">
            <AtomNavLinkHead :content="nav.title" :to="hasSub ? null : nav.to" :sub="hasSub" />
          </div>
        </template>
        <template v-if="nav.items" slot="accordion-content">
          <div class="lists border-t border-gray-300 py-4" :class="{ 'grid grid-cols-2 items-start': nav.subTitle }">
            <AtomButton v-if="nav.subTitle" class="!text-gray-500 !text-sm !justify-start !px-0" :to="nav.to">
              {{ nav.subTitle }}
            </AtomButton>
            <div>
              <div v-for="item in nav.items" :key="item.to" class="flex flex-col">
                <AtomButton
                  class="!text-gray-500 !text-sm !justify-start !px-0 !font-light"
                  :to="item.to"
                  :href="item.href"
                  :tag="item.href ? 'a' : 'button'"
                >
                  {{ item.title }}
                </AtomButton>
              </div>
            </div>
          </div>
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '../../atoms/accordion/AtomAccordion'
import AccordionItem from '../../atoms/accordion/AtomAccordionItem'
import AtomButton from '../../atoms/AtomButton'
import AtomNavLinkHead from '../../atoms/layouts/AtomNavLinkHead'

export default {
  name: 'MoleculeNavListLink',
  components: {
    AtomButton,
    AtomNavLinkHead,
    Accordion,
    AccordionItem,
  },
  props: ['nav'],
  computed: {
    hasSub() {
      return Boolean(this.nav.items && this.nav.items.length > 0)
    },
  },
}
</script>

<style lang="postcss">
.nav-mobile-item {
  .plusminus {
    &:before,
    &:after {
      @apply bg-gray-400;
    }
  }
  .box-text {
    @apply font-light py-[0.95rem];
  }
}
.nav-head-mobile {
  @apply -md:w-full;
  .link {
    @apply text-gray-500 -md:w-full;
  }
  .link-sub {
    @apply hidden;
  }
}
.sub-link {
  &.sub-fixed {
    .sub-nav-wrap {
      @apply left-auto;
    }
    .sub-nav-bg {
      width: 200vw;
      left: -100vw;
    }
    .sub-nav-link {
      padding: 0;
    }
  }
  &.sub-not {
    .sub-nav-wrap .sub-nav-bg {
      @apply !transition-none;
    }
  }
}
.nav-head {
  a {
    .box-text {
      &:before {
        content: '';
        position: absolute;
        bottom: -2px;
        right: 0;
        left: 0;
        height: 3px;
        background-color: #47474a;
        /* transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
        transform: scaleX(0);
        transform-origin: right center;
      }
    }
  }
}
.sub-nav-wrap {
  transition: visibility 0s linear;
  transition-delay: 0.6s;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  .sub-nav-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    background-color: #fff;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateY(-80%);
    opacity: 0;
    /* box-shadow: 0 5px 10px 0 rgb(27 34 92 / 5%); */
  }
  .sub-nav-link {
    /* position: relative; */
    z-index: 2;
    transform: translateY(-20px);
    opacity: 0;
  }
}
</style>
