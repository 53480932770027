export const state = () => ({
  counter: 0,
  animation: animationStates.NO_ACTION,
  skip: false,
})

export const getters = {
  canNavigate(state) {
    return state.animation === animationStates.NO_ACTION
  },
  entering(state, { canNavigate }) {
    return canNavigate || state.animation === animationStates.ENTER_IN_ACTION
  },
}

export const mutations = {
  increaseCounter(state) {
    state.counter++
  },
  setAnimationState(state, animationState) {
    state.animation = animationState
  },
  skipNext(state, skip) {
    state.skip = skip
  },
}

export const actions = {
  leave({ state, commit, dispatch }) {
    commit('setAnimationState', animationStates.LEAVE_IN_ACTION)
    return guard(state, commit, resolve => {
      if (state.skip) return resolve()
      const el = document.querySelector('.page-transition')
      el.ontransitionend = () => {
        resolve()
      }
      el.classList.remove('enter')
      el.classList.add('leave')
    })
  },
  enter({ state, commit, dispatch }) {
    commit('setAnimationState', animationStates.ENTER_IN_ACTION)
    dispatch('scrollBlocker/unblock', 'routing', { root: true })
    return guard(state, commit, resolve => {
      if (state.skip) return resolve()
      const el = document.querySelector('.page-transition')
      el.ontransitionend = () => {
        resolve()
      }
      if (el.classList.contains('leave')) {
        el.classList.remove('leave')
        el.classList.add('enter')
      } else {
        resolve()
      }
    })
  },
  toDefault({ state, commit }) {
    return guard(state, commit, resolve => {
      const el = document.querySelector('.page-transition')
      el.classList.remove('enter')
      commit('setAnimationState', animationStates.NO_ACTION)
      commit('skipNext', false)
      resolve()
    })
  },
  skip({ commit }) {
    commit('skipNext', true)
  },
  unskip({ commit }) {
    commit('skipNext', false)
  },
}

function guard(state, commit, fn) {
  commit('increaseCounter')
  const consistency = state.counter
  return new Promise((resolve, reject) => {
    fn(data => {
      if (state.counter !== consistency) {
        // reject(new Error('INCONSISTENCY'))
      }
      resolve(data)
    })
  })
}

const animationStates = {
  LEAVE_IN_ACTION: 'LEAVE_IN_ACTION',
  ENTER_IN_ACTION: 'ENTER_IN_ACTION',
  NO_ACTION: 'NO_ACTION',
}
