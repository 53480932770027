import Vue from 'vue'
Vue.mixin({
  methods: {
    sendReachGoal(eventName = '', objParams = {}) {
      // success-form-send - успешная отправка формы
      // open-promo - Открытие модалки со специальным предложением (акции)
      
      if (window?.ym) window.ym(37069155, 'reachGoal', eventName)
      else console.log('Отправка без регистрации события');
    }
  }
})
