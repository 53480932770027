const routes = [
  {
    name: 'home',
    path: '/',
    component: 'pages/index.vue',
  },
  {
    name: 'timetable',
    path: '/timetable',
    component: 'pages/timetable/index.vue',
    children: [
      {
        name: 'timetable_club',
        path: '/timetable/:club/:age',
        component: 'pages/timetable/_club/_age.vue',
      },
    ],
  },
  {
    name: 'tvtimetable',
    path: '/tvtimetable/:club',
    component: 'pages/tvtimetable/index.vue',
  },
  {
    name: 'team',
    path: '/team',
    component: 'pages/team/index.vue',
    custom: {
      getRedirectPath({ store }, to, from) {
        const toParent = to.name === 'team' && from.matched[0].name === 'team'
        if (toParent) return
        const geoClub = store.getters['modules/content/geopositionClub']
        return `/team/${geoClub.slug}`
      },
    },
    children: [
      {
        name: 'team_club',
        path: '/team/:club',
        component: 'pages/team/_club/index.vue',
        children: [
          {
            name: 'team_club_trainer',
            path: '/team/:club/trainer/:trainer',
            component: 'pages/team/_club/trainer/_trainer.vue',
          },
        ],
      },
    ],
  },
  {
    name: 'career',
    path: '/career',
    component: 'pages/career.vue',
  },
  {
    path: '/spa',
    component: 'pages/spa/index.vue',
    children: [
      {
        name: 'spa',
        path: '',
        component: 'pages/spa/list.vue',
      },
      {
        name: 'spa_map',
        path: '/spa/map',
        component: 'pages/spa/map.vue',
      },
    ],
  },
  {
    name: 'spa_club',
    path: '/spa/:id',
    component: 'pages/spa/_id.vue',
  },
  {
    path: '/contacts',
    component: 'pages/contacts/index.vue',
    children: [
      {
        name: 'contacts',
        path: '',
        component: 'pages/contacts/list.vue',
      },
      {
        name: 'contacts_map',
        path: '/contacts/map',
        component: 'pages/contacts/map.vue',
      },
    ],
  },
  {
    path: '/clubs',
    component: 'pages/clubs/index.vue',
    children: [
      {
        name: 'clubs',
        path: '',
        component: 'pages/clubs/list.vue',
      },
      {
        name: 'clubs_map',
        path: '/clubs/map',
        component: 'pages/clubs/map.vue',
      },
      {
        name: 'clubs_club_promo',
        path: '/clubs/:id/promo/:slug',
        component: 'pages/clubs/promo/_slug.vue',
      },
    ],
  },
  {
    name: 'club',
    path: '/clubs/:id',
    component: 'pages/clubs/_id.vue',
  },
  {
    name: 'myzone',
    path: '/fitness/myzone',
    component: 'pages/fitness/myzone.vue',
  },
  {
    name: 'group-training',
    path: '/fitness/group-training',
    component: 'pages/fitness/group-training.vue',
  },
  {
    name: 'mini-group',
    path: '/fitness/mini-group',
    component: 'pages/fitness/mini-group.vue',
  },
  {
    name: 'personal-training',
    path: '/fitness/personal-training',
    component: 'pages/fitness/personal-training.vue',
  },
  {
    name: 'testing',
    path: '/fitness/testing',
    component: 'pages/fitness/testing.vue',
  },
  {
    name: 'kids-clubs',
    path: '/fitness/kids-clubs',
    component: 'pages/fitness/kids-clubs.vue',
  },
  {
    name: 'payment',
    path: '/payment',
    component: 'pages/payment.vue',
  },
  {
    name: 'about',
    path: '/about',
    component: 'pages/about.vue',
  },
  {
    name: 'franchise',
    path: '/franchise',
    component: 'pages/franchise.vue',
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    component: 'pages/privacy-policy.vue',
  },
]

export default routes
