<template>
  <div class="inline-flex items-end w-full h-[41px] relative input-container">
    <vue-tel-input
      ref="telInput"
      v-model="phoneValue"
      class="input border-b"
      :class="{ [`${theme || 'white'}`]: true }"
      v-bind="vueTel.props"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @country-changed="countryChanged"
    ></vue-tel-input>
    <AtomInputLabel ref="label" :id-for="id" :label="label" :theme="theme" />
  </div>
</template>

<script>
import AtomInputLabel from '../../atoms/forms/AtomInputLabel'

export default {
  name: 'MoleculeInputPhone',
  components: {
    AtomInputLabel,
  },
  props: ['label', 'id', 'type', 'theme', 'value'],
  data() {
    return {
      phoneValue: '',
      vueTel: {
        props: {
          placeholder: ' ',
          maxLen: 16,
          defaultCountry: 'RU',
          ignoredCountries: ['GH'],
          disabledFetchingCountry: true,
          validCharactersOnly: true,
          enabledFlags: false,
          wrapperClasses: 'country-phone-input',
          dropdownOptions: {
            disabled: true,
          },
        },
      },
    }
  },
  methods: {
    onBlur(value) {
      if (!this.phoneValue.length) {
        this.$refs.label.$el.classList.remove('label-activated')
      }
      this.$emit('onBlur', this.phoneValue)
    },
    onInput(number, obj) {
      let formattedNumber = this.phoneValue
      if (formattedNumber[0] === '8') formattedNumber = this.replaceAt(formattedNumber, 0, '+7 ')

      this.phoneValue = formattedNumber.replace(/[()]/g, '')
      this.$emit('onInput', this.phoneValue, obj)
    },
    onFocus() {
      if (!this.phoneValue.length) this.$refs.label.$el.classList.add('label-activated')
    },
    countryChanged(object) {
      if (object.iso2 !== 'RU') this.$refs.telInput.choose('RU')
    },
    replaceAt(str, index, replacement) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length)
    },
  },
}
</script>

<style lang="postcss" scoped>
.label-activated {
  @apply text-gray-500 border-white;
  transform: translateY(-20px) scale(0.75);
}

.input-container {
  &[error~='true'] {
    .placeholder {
      @apply !text-red;
    }
    input {
      @apply !border-red !text-red;
      border-color: red;
    }
  }
}
</style>

<style lang="postcss">
.input-container {
  &[error~='true'] {
    .placeholder {
      @apply !text-red;
    }
    .vue-tel-input {
      @apply !text-red;
    }
    .vue-tel-input input {
      @apply !border-red !text-red;
    }
  }
}

.vue-tel-input:focus-within {
  box-shadow: none !important;
}
.vue-tel-input {
  outline: none !important;
  border: none !important;
  border-radius: 0 !important;
  @apply w-full p-0 border-b outline-none bg-transparent appearance-none rounded-none;
  -webkit-appearance: none;
  input {
    @apply w-full p-0 border-none border-solid border-b outline-none bg-transparent appearance-none rounded-none;
    border-bottom: 1px solid;
  }
  &.dark {
    @apply text-gray-500 hover:text-white active:text-white hover:border-white active:border-white;
    input {
      @apply border-gray-300;
    }
    /* input:-webkit-autofill:hover, */
    input:-webkit-autofill,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-internal-autofill-selected {
      -webkit-text-fill-color: #fff;
    }
    /* input {
      @apply text-gray-500 hover:text-white active:text-white hover:border-white active:border-white;
    } */
    &:hover {
      transition: border 1s var(--cubic-bezier);
      input {
        transition: border 1s var(--cubic-bezier);
        @apply border-gray-500;
      }
      & ~ .placeholder {
        @apply text-white;
      }
    }
  }
  &.white {
    @apply border-gray-500 hover:border-gray-100;
    input {
      @apply border-gray-500 hover:border-gray-100;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-internal-autofill-selected {
      -webkit-text-fill-color: rgb(31 41 55);
    }
  }
  input {
    @apply w-full p-0 border-b outline-none bg-transparent appearance-none rounded-none;
  }
  input::focus {
    outline: none;
  }
}
</style>
