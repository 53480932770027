<template>
  <div v-if="mobileOnly && !isMobile">
    <slot />
  </div>
  <div
    v-else
    ref="holder"
    :class="{ 'pb-[4px]': axis === 'x', 'pr-[2px]': axis === 'y', [theme]: true }"
    class="relative overflow-hidden"
  >
    <div
      ref="inner"
      :class="{
        'mb-[-20px] pb-5 overflow-x-scroll': axis === 'x',
        'mr-[-30px] overflow-y-scroll pr-8': axis === 'y',
        'xl:pr-10': axis === 'y' && !hiddenTrack,
      }"
      @scroll.capture="handleScroll"
      @mouseover="blockLocoScroll(true)"
      @mouseleave="blockLocoScroll(false)"
      @touchstart="blockLocoScroll(true)"
      @touchcancel="blockLocoScroll(false)"
      @touchend="blockLocoScroll(false)"
    >
      <slot />
    </div>
    <div
      :class="{
        'inset-x-0 bottom-1': axis === 'x',
        'inset-y-0 right-0 flex scroll-bar': axis === 'y',
        hidden: hiddenTrack,
      }"
      class="absolute"
    >
      <div
        :class="{
          'h-[3px] bg-repeat-x': axis === 'x',
          'w-[3px] bg-repeat-y': axis === 'y',
        }"
        class="track"
      ></div>
      <div
        ref="thumb"
        :class="{
          'h-[3px] mt-[-4px]': axis === 'x',
          'w-[3px] ml-[-4px]': axis === 'y',
          'bg-gray-100': theme === 'white',
          'bg-gray-500': theme === 'dark',
        }"
        class="rounded-full"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axis: {
      type: String,
      default: 'x',
    },
    mobileOnly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'white',
    },
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: false,
      hiddenTrack: false,
    }
  },
  watch: {
    blocked() {
      this.blockLocoScroll(this.blocked)
    },
  },
  beforeMount() {
    this.isMobile = window.innerWidth < 960
  },
  beforeDestroy() {
    this.blockLocoScroll(false)
  },
  mounted() {
    this.calc()
    this.$refs.holder && new ResizeObserver(this.calc).observe(this.$refs.holder.parentNode)
  },
  methods: {
    handleScroll(e) {
      if (this.axis === 'x') {
        const scrollSpace = this.$refs.inner.scrollWidth - this.rects.inner.width
        const trackSpace = this.rects.inner.width - this.rects.thumb.width
        const percent = this.$refs.inner.scrollLeft / scrollSpace
        this.$refs.thumb.style.transform = `translateX(${trackSpace * percent}px)`
      } else {
        const scrollSpace = this.$refs.inner.scrollHeight - this.rects.inner.height
        const trackSpace = this.rects.inner.height - this.rects.thumb.height
        const percent = this.$refs.inner.scrollTop / scrollSpace
        this.$refs.thumb.style.transform = `translateY(${trackSpace * percent}px)`
      }
    },
    blockLocoScroll(shouldBlock) {
      if (this.axis === 'x') return
      if (shouldBlock) {
        this.$store.dispatch('scrollBlocker/block', 'custom-scroll')
      } else {
        this.$store.dispatch('scrollBlocker/unblock', 'custom-scroll')
      }
    },
    calc() {
      if (!this.$refs.inner) return
      if (this.mobileOnly && !this.isMobile) return
      const rectInner = this.$refs.inner.getBoundingClientRect()
      const rectHolder = this.$refs.holder.parentNode.getBoundingClientRect()
      if (this.axis === 'x') {
        this.$refs.holder.style.cssText = `height: ${rectInner.height - 20}px`
        const thumbSize = (rectHolder.width / this.$refs.inner.scrollWidth) * 100
        this.$refs.thumb.style.width = `${thumbSize}%`
        this.hiddenTrack = thumbSize >= 99.9
      } else {
        this.$refs.holder.style.cssText = `width: calc(100% -20px)`
        const thumbSize = (rectHolder.height / this.$refs.inner.scrollHeight) * 100
        this.$refs.thumb.style.height = `${thumbSize}%`
        this.hiddenTrack = thumbSize >= 99.9
      }

      this.rects = {
        inner: rectInner,
        holder: rectHolder,
        thumb: this.$refs.thumb.getBoundingClientRect(),
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.white {
  .track {
    background-image: url("data:image/svg+xml,%3Csvg width='1' height='4' viewBox='0 0 1 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H1V1H0V0Z' fill='%23282929'/%3E%3C/svg%3E%0A");
  }
}
.dark {
  .track {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='8' viewBox='0 0 1 8' fill='none'%3E%3Cline x1='0.5' y1='2' x2='0.5' y2='8' stroke='%23CFD1D6' stroke-dasharray='1 3'/%3E%3C/svg%3E");
  }
}
</style>
