<template>
  <div class="radio-container flex w-full relative" :class="{ [type]: true }">
    <AtomLabel :label="label" :disabled="disabled" class="cursor-pointer label">
      <AtomRadio :id="id" :disabled="disabled" :type="type" :value="value" :checked="checked" @change="change" />
      <span class="input-box" />
    </AtomLabel>
  </div>
</template>

<script>
import AtomLabel from '../../atoms/forms/AtomLabel'
import AtomRadio from '../../atoms/forms/AtomRadio'

export default {
  name: 'MoleculeRadio',
  components: {
    AtomRadio,
    AtomLabel,
  },
  props: ['label', 'id', 'disabled', 'type', 'value', 'checked'],
  methods: {
    change(v) {
      this.$emit('input', v)
    },
  },
}
</script>

<style lang="postcss" scoped>
input:checked + label,
input:not(:checked) + label {
  @apply relative cursor-pointer;
}
input:checked + .input-box:before,
input:not(:checked) + .input-box:before {
  content: '';
  @apply inline-flex relative top-[3px] mr-2 min-w-[18px] w-[18px] h-[18px] border border-gray-100 rounded-full bg-transparent;
}
input:checked + .input-box:after,
input:not(:checked) + .input-box:after {
  content: '';
  @apply w-3 h-3 absolute top-[6px] left-[3px] rounded-full bg-gray-100;
  transition: all 0.2s ease;
}
input:checked[type='checkbox'] + .input-box:after {
  @apply w-3 h-3 absolute top-[14px] left-[3px] rounded-full bg-gray-100;
}
.checkbox {
  .label {
    @apply !text-base;
  }
}
.radio-container.checkbox {
  @apply items-center py-2;
  label input + .input-box:before,
  label input + .input-box:after {
    @apply !rounded-sm;
  }
}
input:not(:checked) + .input-box:after {
  opacity: 0;
  transform: scale(0);
}
input:checked + .input-box:after {
  opacity: 1;
  transform: scale(1);
}
input:disabled + label {
  @apply text-gray-500 cursor-not-allowed;
  &:before {
    @apply border-gray-500;
  }
}
.dark {
  input:checked + .input-box:before,
  input:not(:checked) + .input-box:before {
    @apply border-gray-400;
  }
  input:checked + .input-box:after,
  input:not(:checked) + .input-box:after {
    @apply bg-gray-400;
  }
  label {
    @apply text-gray-400;
  }
}

.radio-container {
  &[error~='true'] {
    input:checked + .input-box:before,
    input:not(:checked) + .input-box:before {
      @apply !border-red;
    }
    input:checked + .input-box:after,
    input:not(:checked) + .input-box:after {
      @apply !bg-red;
    }
    label {
      @apply !text-red;
    }
  }
}
</style>
