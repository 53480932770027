<template>
  <NuxtLink v-if="to && to !== $nuxt.$route.path" class="inline-flex relative text-base" :to="to">
    {{ content }}&nbsp;/&nbsp;</NuxtLink
  >
  <a v-else-if="href && href !== $nuxt.$route.path" class="inline-flex relative text-base" :href="href">
    {{ content }}&nbsp;/&nbsp;
  </a>
  <span v-else class="inline-flex relative text-base">{{ content }}&nbsp;/&nbsp;</span>
</template>

<script>
export default {
  name: 'AtomBreadcrumb',
  props: ['content', 'to', 'href'],
}
</script>
