<template>
  <li
    class="accordion__item border-b last:border-b-0 border-gray-500 py-3 md:pt-3 md:pb-4"
    :class="{ accordion__item_active: visible }"
  >
    <div class="accordion__trigger" :class="{ accordion__trigger_active: visible }" @click="open">
      <slot name="accordion-trigger"></slot>
      <span v-if="!hide" class="plusminus" />
    </div>

    <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
      <div v-show="visible" class="accordion__content">
        <div>
          <slot name="accordion-content"></slot>
        </div>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  inject: ['Accordion'],
  props: ['hide', 'scrollIntoView'],
  data() {
    return {
      index: null,
    }
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active
    },
  },
  created() {
    this.index = this.Accordion.count++
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null
      } else {
        this.Accordion.active = this.index
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end(el) {
      el.style.height = ''
      if (!this.scrollIntoView) return
      try {
        const childs = this.$slots['accordion-content'][0].children
        const last = childs[childs.length - 1].elm
        const rect = last.getBoundingClientRect()
        if (rect.y + rect.height > window.innerHeight) {
          last.scrollIntoView({ behavior: 'smooth' })
        }
      } catch (e) {}
    },
  },
}
</script>

<style lang="postcss" scoped>
.plusminus {
  @apply relative min-w-[16px] w-4 h-4 md:min-w-[24px] md:w-6 md:h-6 cursor-pointer;

  &.active {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }

  &:before,
  &:after {
    @apply content-[''] block absolute left-0 top-1/2 w-full h-[1px] bg-gray-100;
    transition: 0.35s;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
}
.accordion__item {
  @apply relative;
  &.accordion__item__mobile::after {
    @apply !hidden;
  }
  &:not(.accordion__item_active):after {
    @apply content-[''] h-[1px] w-full block absolute left-0 right-0 bottom-[-1px];
    z-index: 1;
    border-bottom: 1px solid;
    transform: scaleX(0);
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform-origin: center right;
  }
  &:not(.accordion__item_active):hover {
    &::after {
      @apply border-gray-100;
      transform: scaleX(1);
      transition: transform 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform-origin: center left;
    }
  }
}

/* .accordion__content {
  &:after {
    @apply content-[''] h-[1px] w-full block bg-gray-100 absolute left-0 right-0 bottom-[-1px];
  }
} */

.accordion__trigger {
  @apply flex justify-between cursor-pointer items-center;
  &_active {
    .plusminus {
      &:before {
        transform: translatey(-50%) rotate(-90deg);
        opacity: 0;
      }
      &:after {
        transform: translatey(-50%) rotate(0);
      }
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
