<template>
  <a
    href="#"
    class="link inline-flex relative text-gray-500 hover:text-white text-sm md:text-base"
    @click="changeLocale"
  >
    {{ $t('switchLink') }}
  </a>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AtomLinkItem',
  props: ['content', 'to', 'target'],
  computed: {
    ...mapState({
      locale: state => state.modules.locale.locale,
    }),
  },
  methods: {
    ...mapActions({
      setLocale: 'modules/content/setLocale',
    }),
    changeLocale() {
      this.setLocale(this.$i18n.locale === 'ru' ? 'en' : 'ru')
    },
  },
}
</script>
