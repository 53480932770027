<template>
  <section
    class="relative flex flex-col bg-white md:ml-[75px] mt-[-1px]"
    :class="{ [`z-${z}`]: true }"
    data-scroll-section
  >
    <div class="absolute top-[-2px] w-[75px] bottom-[-1px] left-[-75px] hidden md:block bg-gray-100"></div>
    <div>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    z: {
      type: String,
      default: '30',
    },
  },
}
</script>
