<template>
  <div
    class="select"
    :class="{ [`${theme || 'white'}`]: true, opened: opened, 'z-10': opened, 'pointer-events-none': disabled }"
    v-on="handlers"
  >
    <div class="flex w-full relative">
      <AtomInput
        type="text"
        :value="text"
        disabled="disabled"
        :theme="theme"
        :class="{ 'z-20': opened }"
        class="-md:pb-1"
      />
      <AtomInputLabel :label="placeholder" :theme="theme" class="!top-[3px] md:!top-0" />
      <svg
        class="absolute right-0 arrow"
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.19383 0.442383L9.99994 1.24849L5.03684 6.2116L0.0737305 1.24849L0.879841 0.442383L5.03684 4.59938L9.19383 0.442383Z"
          fill="#CFD1D6"
        />
      </svg>
    </div>
    <div v-if="opened" class="dropdown">
      <div>
        <Scroll axis="y" :theme="theme" :blocked="opened">
          <div style="max-height: 180px; overflow: visible">
            <div
              v-for="(item, i) in items"
              :key="i"
              class="dropdown-item"
              :class="{ 'dropdown-item-active': current === item }"
              @click.stop="select(item)"
            >
              {{ item.text }}
            </div>
          </div>
        </Scroll>
      </div>
    </div>
  </div>
</template>

<script>
import AtomInput from '../../atoms/forms/AtomInput'
import AtomInputLabel from '../../atoms/forms/AtomInputLabel'
import Scroll from '../../templates/layouts/Scroll'

export default {
  name: 'AtomSelect',
  components: {
    AtomInputLabel,
    AtomInput,
    Scroll,
  },
  props: ['items', 'value', 'placeholder', 'theme', 'initialIndex', 'disabled', 'entryClub'],
  data() {
    return {
      opened: false,
      current: null,
    }
  },
  computed: {
    text() {
      if (this.value && this.value.text) {
        return this.value.text
      }
      return null
    },
    handlers() {
      if (isTouch()) {
        return {
          touchstart: this.open,
        }
      }
      return {
        mouseover: this.open,
        mouseleave: this.close,
        click: this.close,
      }
    },
  },
  mounted() {
    const querySlug = this.$route?.query?.club
    const entryClub = this.entryClub

    if (querySlug || entryClub) {
      let item = null
      querySlug ? (item = this.setSelect(querySlug)) : (item = this.setSelect(entryClub.slug))
      if (item !== null) this.select(item)
    }

    if (isTouch()) {
      this.listener = e => {
        if (this.$el && this.$el.contains(e.target)) return
        this.opened && this.close()
      }
      document.body.addEventListener('touchstart', this.listener, true)
    }
    if (typeof this.initialIndex === 'number') {
      this.select(this.items[this.initialIndex])
    }
  },
  beforeDestroy() {
    this.listener && document.body.removeEventListener('touchstart', this.listener)
  },
  methods: {
    select(item) {
      this.opened = false
      this.current = item
      this.$emit('input', item)
    },
    open() {
      if (!this.items?.length) return
      this.opened = true
      document.activeElement && document.activeElement.blur()
    },
    close() {
      this.opened = false
    },
    setSelect(slug) {
      return this.items.find(item => item.slug === slug)
    },
  },
}

function isTouch() {
  if (!process.client) return false
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}
</script>

<style lang="postcss" scoped>
.select {
  @apply inline-flex items-end w-[calc(100%_+_24px)] md:w-[calc(100%_+_54px)] h-[41px] relative mx-[-12px] px-3 md:mx-[-27px] md:px-[27px];
  .input {
    -webkit-text-fill-color: #282929;
    @apply opacity-100;
  }
  &[error~='true'] {
    .input {
      @apply !border-red !text-red;
    }
    .placeholder {
      @apply !text-red;
    }
    svg path {
      @apply !fill-red;
    }
  }
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    @apply text-gray-500 border-white;
    transform: translateY(-20px) scale(0.75);
  }

  .dropdown {
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    transition: opacity 0.5s var(--cubic-bezier);
    pointer-events: none;
    @apply absolute top-0 inset-x-0 opacity-0 px-[13px] pt-[60px] pb-[21px] z-10 rounded-[14px];
    &-item {
      border-radius: 14px;
      line-height: 37px;
      @apply text-white align-middle pl-[14px] cursor-pointer;
      &:hover {
        @apply bg-gray-300;
      }
    }
  }
  &.opened {
    .placeholder {
      @apply z-20 text-gray-400;
    }
    .dropdown {
      opacity: 1;
      pointer-events: all;
    }
    .input:not(:placeholder-shown) ~ .placeholder {
      @apply hidden;
    }
  }

  &.white {
    .dropdown-item {
      @apply text-gray-100 hover:bg-gray-600;
      &.dropdown-item-active {
        @apply bg-gray-600;
      }
    }
    .dropdown {
      @apply bg-white;
    }
    .arrow path {
      fill: #282929;
    }
  }
  &.dark {
    .input {
      -webkit-text-fill-color: rgb(207, 209, 214);
      @apply opacity-100;
    }
    .dropdown-item {
      &.dropdown-item-active {
        @apply bg-gray-300;
      }
    }
    .input.dark {
      @apply border-gray-300 active:border-gray-300 hover:border-gray-300 hover:text-gray-500;
    }
    .dropdown {
      @apply bg-gray-100;
    }
  }
}
</style>
