import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const DAYS = [0, 1, 2, 3, 4, 5, 6]

export const state = () => ({
  date: new Date(),
  adult: [],
  kids: []
})

export const getters = {
  now(state) {
    return state.timezone ? dayjs(dayjs(state.date).tz(state.timezone).format('YYYY-MM-DD HH:mm')) : dayjs(state.date)
  },
  timetableAdult(state, getters) {
    return getFliteredTimetable(
      state.adult,
      getters
    )
  },
  timetableKids(state, getters) {
    return getFliteredTimetable(
      state.kids,
      getters
    )
  }
}

export const mutations = {
  SET_ITEMS(state, {data, age}) {
    state[age] = data
  },
  SET_DATE(state, data) {
    state.date = data
  },
  SET_DATE_TIMEZONE(state, data) {
    state.timezone = data
  },
}

export const actions = {
  setDate({ commit }, data) {
    commit('SET_DATE', data)
  },
  setDateTimezone({ commit }, data) {
    commit('SET_DATE_TIMEZONE', data)
  },
  async getTimetable({ commit, state }, params) {
    try {
      const date = dayjs(params.date, 'YYYY-MM-DD', true)
      const today = dayjs().format('YYYY-MM-DD')
      const dateRangeParams = date.isValid()
        ? {
          dateFrom: date.format('YYYY-MM-DD'),
          dateTo: date.add(7, 'day').format('YYYY-MM-DD'),
        }
        : {
          dateFrom: dayjs().format('YYYY-MM-DD'),
          dateTo: dayjs().add(7, 'day').format('YYYY-MM-DD'),
        }
      const response = await this.$axios({
        url: `/api/fitbase/${params.club}/classes`,
        method: 'get',
        params: {
          ...dateRangeParams,
          'filters[age]': params.age,
        },
      })

      const data = response.data || []
      commit('SET_ITEMS', {data, age: params.age})
      return true
    } catch (e) {
      return false
    }
  },
}

function getFliteredTimetable(classes, getters, predicate) {
  const filtered = classes.filter((item) => {
    return getters.now.isBefore(`${item.date} ${item.time_start}`)
  })
  if (!filtered.length) return []
  const firstItemDate = dayjs(filtered[0].date)
  return filtered
    .filter(({
      date
    }) => firstItemDate.isSame(date, 'day'))
    .slice(0, 6)
    .map((item) => {
      return {
        date: item.date,
        time: dayjs(`${item.date} ${item.time_start}`).format('HH:mm'),
        name: item.name,
        zone: item.place?.name
      }
    })
}
