<template>
  <NuxtLink
    v-if="to"
    :to="to"
    class="btn"
    :class="disabled"
    :disabled="disabled"
    exact-path
    @click.native="$emit('click')"
  >
    <slot />
  </NuxtLink>
  <a v-else-if="tel" type="tel" class="btn btn-tel" :href="`tel:${tel}`" @click="$emit('click')">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4775 12.7475C15.4775 13.0175 15.4175 13.295 15.29 13.565C15.1625 13.835 14.9975 14.09 14.78 14.33C14.4125 14.735 14.0075 15.0275 13.55 15.215C13.1 15.4025 12.6125 15.5 12.0875 15.5C11.3225 15.5 10.505 15.32 9.6425 14.9525C8.78 14.585 7.9175 14.09 7.0625 13.4675C6.2 12.8375 5.3825 12.14 4.6025 11.3675C3.83 10.5875 3.1325 9.77 2.51 8.915C1.895 8.06 1.4 7.205 1.04 6.3575C0.68 5.5025 0.5 4.685 0.5 3.905C0.5 3.395 0.59 2.9075 0.77 2.4575C0.95 2 1.235 1.58 1.6325 1.205C2.1125 0.7325 2.6375 0.5 3.1925 0.5C3.4025 0.5 3.6125 0.545 3.8 0.635C3.995 0.725 4.1675 0.86 4.3025 1.055L6.0425 3.5075C6.1775 3.695 6.275 3.8675 6.3425 4.0325C6.41 4.19 6.4475 4.3475 6.4475 4.49C6.4475 4.67 6.395 4.85 6.29 5.0225C6.1925 5.195 6.05 5.375 5.87 5.555L5.3 6.1475C5.2175 6.23 5.18 6.3275 5.18 6.4475C5.18 6.5075 5.1875 6.56 5.2025 6.62C5.225 6.68 5.2475 6.725 5.2625 6.77C5.3975 7.0175 5.63 7.34 5.96 7.73C6.2975 8.12 6.6575 8.5175 7.0475 8.915C7.4525 9.3125 7.8425 9.68 8.24 10.0175C8.63 10.3475 8.9525 10.5725 9.2075 10.7075C9.245 10.7225 9.29 10.745 9.3425 10.7675C9.4025 10.79 9.4625 10.7975 9.53 10.7975C9.6575 10.7975 9.755 10.7525 9.8375 10.67L10.4075 10.1075C10.595 9.92 10.775 9.7775 10.9475 9.6875C11.12 9.5825 11.2925 9.53 11.48 9.53C11.6225 9.53 11.7725 9.56 11.9375 9.6275C12.1025 9.695 12.275 9.7925 12.4625 9.92L14.945 11.6825C15.14 11.8175 15.275 11.975 15.3575 12.1625C15.4325 12.35 15.4775 12.5375 15.4775 12.7475Z"
        stroke="#282929"
        stroke-miterlimit="10"
      />
    </svg>
  </a>
  <component
    :is="tag"
    v-else
    :href="href"
    v-bind="href && { target: '_blank' }"
    class="btn"
    :class="disabled"
    :disabled="disabled && 'disabled'"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AtomButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    tel: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>

<style lang="postcss">
.btn {
  @apply relative overflow-hidden inline-flex items-center text-center border rounded-[51px] justify-center;
  transform: translateZ(0);
  transition: border 0.65s var(--ease-in-out-soft);

  &:not(:disabled):not(.disabled):before {
    @apply absolute left-0 w-full z-[-1] bottom-[-0.1rem] content-[''];
    height: calc(100% + 0.2rem);
    transform: translate3d(0, 100%, 0);
    transition: transform 0.3s var(--ease-out);
  }

  &:not(:disabled):not(.disabled):not(:active):hover:before {
    transform: translateZ(0);
  }

  &:disabled,
  &.disabled {
    @apply !text-white !bg-gray-500 !border-gray-500 cursor-default;
  }
  &.disabled-black {
    @apply !text-gray-100 !bg-gray-600 !border-gray-600 cursor-default;
  }

  &:not(.btn-fill):not(.btn-white) {
    @apply text-gray-100 border-gray-100;

    &:before {
      @apply bg-gray-100;
    }

    &:not(:disabled):not(.disabled):not(:active):hover {
      @apply text-white;
    }
  }

  &:not(.btn-sm) {
    @apply text-base md:text-[30px] md:leading-[115%] py-[5px] px-4 md:pt-[13px] md:pb-[10px] md:px-[36px];
  }

  &:not(.btn-sm) {
    @apply inline-flex;
  }

  &-sm {
    @apply text-sm md:text-base py-2 px-4 md:px-5;
  }

  /* &.btn-size-fixed {
    &:not(.btn-sm) {
      font-size: 30px;
      line-height: 115%;
    }
  } */

  &-tel {
    @apply rounded-full !px-[10px] md:!px-4 aspect-square;
    svg {
      @apply -md:w-[14px];
    }
    &:hover {
      svg path {
        @apply stroke-white;
      }
    }
  }

  &-fill {
    @apply bg-gray-100 text-white;

    &:before {
      @apply bg-white;
    }

    &:not(:disabled):not(.disabled):hover {
      @apply text-gray-100;
    }
  }
  &-fill-white {
    @apply bg-white text-gray-100 !border-0;

    &:before {
      @apply bg-gray-100;
    }

    &:not(:disabled):not(.disabled):hover {
      @apply text-white;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  &-white {
    @apply !border-white text-white;

    &:before {
      @apply !bg-white;
    }

    &:not(:disabled):not(.disabled):hover {
      @apply text-gray-100;
    }
    &:hover:not(:active):not(:disabled):not(.disabled) {
      path {
        @apply !fill-gray-100;
      }
    }
    &:not(:disabled):not(.disabled):active {
      @apply text-white;
    }
  }

  &-outline {
    @apply !border-gray-500 hover:!border-gray-100;
    &:not(:disabled):not(.disabled):hover {
      @apply text-gray-100;
    }
    &.disabled {
      @apply !bg-gray-600 !border-gray-600 !text-gray-100;
    }
    &.btn-white {
      &.disabled,
      &:disabled {
        @apply !bg-transparent !border-gray-400 !text-gray-400;
      }
    }
  }

  &-map {
    @apply md:!border-2 !border-gray-100;
  }

  &-social {
    @apply !border-gray-300 md:!border-gray-500 rounded-[45px] bg-gray-100 text-white;

    &:not(.btn-sm) {
      @apply py-3 px-4 md:px-6 md:py-4;
    }

    svg {
      @apply h-[18px] w-auto md:h-[24px];
    }

    path {
      transition: fill 0.3s var(--ease-out);
    }

    &:hover:not(:active):not(:disabled):not(.disabled) {
      path {
        fill: #282929;
      }
    }

    &:before {
      @apply !bg-gray-500;
    }

    &:not(:disabled):not(.disabled):hover {
      @apply text-gray-100;
    }

    &:disabled,
    .disabled {
      @apply !border-gray-300 !bg-transparent;

      &:before {
        @apply !bg-transparent;
      }

      path {
        fill: #47474a;
      }
    }
  }
  &-club {
    &:disabled,
    &.disabled {
      @apply !text-gray-100 !border-gray-600 !bg-gray-600;
    }
  }
  &-icon {
    @apply rounded-full !p-0 md:!p-4;
    svg {
      @apply -md:w-3;
    }
    &.btn-lg {
      @apply min-w-[32px] w-8 h-8 md:min-w-[56px] md:w-14 md:h-14;
    }
    &:hover:not(:active):not(:disabled):not(.disabled) {
      path {
        @apply fill-white;
      }
    }
  }
}
</style>
