<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  provide() {
    return { Accordion: this.Accordion }
  },
  props: {},
  data() {
    return {
      Accordion: {
        count: 0,
        active: null,
      },
    }
  },
}
</script>

<style lang="postcss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 0;
  .accordion__item {
    @apply last:!border-b;
  }
}
</style>
