<template>
  <MoleculeFooterLastLink :links="links" />
</template>

<script>
import MoleculeFooterLastLink from '../../molecules/layouts/MoleculeFooterLastLink'

export default {
  name: 'TemplateFooterMenuLastNav',
  components: {
    MoleculeFooterLastLink,
  },
  computed: {
    links() {
      return [
        {
          href: '/privacy-policy',
          content: 'Политика конфиденциальности',
        },
        {
          to: '/payment',
          content: 'Методы оплаты',
        },
        // {
        //   to: '/',
        //   content: 'Switch to English',
        // },
      ].filter(Boolean)
    },
  },
}
</script>
