import baseRoutes from '../pages/routes.js'

const flattenRoutes = flat(baseRoutes)

export default function (context) {
  const { app, store } = context
  app.router.beforeEach((to, from, next) => {
    if (!to.name) return next()
    const base = findBaseRoute(to)
    const isFirstEnter = !from.name
    if (base?.custom?.getRedirectPath && !isFirstEnter) {
      const redirectTo = base.custom.getRedirectPath(context, to, from)
      if (redirectTo) return next(redirectTo)
      return next(false)
    }
    return next()
  })
  app.router.onError((e) => {
    debugger
  })
}

function flat(r) {
  return r.reduce((acc, route) => {
    return acc.concat(route).concat(flat(route.children || []))
  }, [])
}

function findBaseRoute(to) {
  const match = to.matched[to.matched.length - 1]
  return flattenRoutes.find((r) => {
    return r.name === match.name
      && (
        to.matched.length > 1
        || r.path.replace(/\/$/, '') === match.path.replace(/\/$/, '')
      )
  })
}