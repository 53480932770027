<template>
  <NuxtLink v-if="to" class="link inline-flex relative text-white whitespace-nowrap" :to="to">
    <span class="wrap-text">
      <span class="box-text inline-flex text-[20px] leading-[24px] pt-[23px] pb-[21px] relative tracking-[-.02em]">{{
        content
      }}</span>
      <span v-if="sub" class="link-sub inline-flex items-center ml-[6px] relative top-[-2px]">
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 2L5 5L2 2" stroke="#ffffff" stroke-width="1.5" stroke-linecap="square" />
        </svg>
      </span>
    </span>
  </NuxtLink>
  <span v-else class="link inline-flex relative text-white whitespace-nowrap">
    <span class="wrap-text">
      <span class="box-text inline-flex text-[20px] leading-[24px] pt-[23px] pb-[21px] relative tracking-[-.02em]">{{
        content
      }}</span>
      <span v-if="sub" class="link-sub inline-flex items-center ml-[6px] relative top-[-2px]">
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 2L5 5L2 2" stroke="#ffffff" stroke-width="1.5" stroke-linecap="square" />
        </svg>
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'AtomNavLinkHead',
  props: ['content', 'to', 'sub'],
}
</script>
