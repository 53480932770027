<template>
  <div class="flex flex-col md:grid grid-cols-2 bg-gray-100">
    <div
      v-for="link in footerLinks"
      :key="link.name"
      class="inline-flex items-center justify-center w-full md:border-l border-b border-gray-300"
    >
      <AtomLink
        :to="link.to"
        :content="link.content"
        class="dark py-5 md:py-[70px] w-full inline-flex justify-center"
      />
    </div>
  </div>
</template>

<script>
import AtomLink from '../../atoms/AtomLink'

export default {
  name: 'MoleculeFooterLinks',
  components: {
    AtomLink,
  },
  props: ['footerLinks'],
}
</script>
