<template>
  <input
    :id="id"
    class="input"
    :type="type"
    :disabled="disabled"
    :value="value"
    :checked="checked"
    @change="type === 'checkbox' && change($event.target.value)"
    @click="type === 'radio' && change(!checked)"
  />
</template>

<script>
export default {
  name: 'AtomRadio',
  props: ['id', 'disabled', 'type', 'value', 'checked'],
  emits: ['input'],
  methods: {
    change(v) {
      this.$emit('change', v)
    },
  },
}
</script>

<style lang="postcss" scoped>
[type='radio']:checked,
[type='radio']:not(:checked),
[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
}
</style>
