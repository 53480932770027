export const state = () => ({
  resources: null,
})

export const getters = {
  preloading(state) {
    return !state.resources || state.resources.length > 0
  },
}

export const mutations = {
  resourceComplete(state, node) {
    state.resources = state.resources.filter(n => n !== node)
  },
  waitForResources(state, nodes) {
    state.resources = nodes
  },
}

export const actions = {
  watchResourcesLoad({ state, commit }) {
    const imgs = [...document.querySelectorAll('[data-resource-loader-context] img')]
      .filter(img => !img.complete)
    const videos = [...document.querySelectorAll('[data-resource-loader-context] video')]
      .filter(video => {
        const noSource = (video.networkState === VIDEO_NETWORK_STATES.NETWORK_LOADING
          || video.networkState === VIDEO_NETWORK_STATES.NETWORK_NO_SOURCE)
          && video.readyState !== VIDEO_READY_STATES.HAVE_NOTHING
        return (
          video.readyState < VIDEO_READY_STATES.HAVE_FUTURE_DATA
          && !noSource
        )
      })

    imgs.forEach(img => {
      img.onload = img.onerror = () => {
        commit('resourceComplete', img)
      }
    })
    videos.forEach(video => {
      const source = (video.children || [])[0] || {}
      source.onerror = video.onerror = video.oncanplay = () => {
        commit('resourceComplete', video)
      }
    })
    commit('waitForResources', [...imgs, ...videos])
  },
}

const VIDEO_NETWORK_STATES = {
  NETWORK_LOADING: 2,
  NETWORK_NO_SOURCE: 3,
}

const VIDEO_READY_STATES = {
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4,
}
