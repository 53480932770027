<template>
  <div
    v-if="progress < 5"
    class="preloader-container fixed inset-0 z-[80] flex items-center justify-center"
    :class="{ 'preloader-hide': progress > 0 }"
    @transitionend.once="progress = 3"
  >
    <div class="preloader-bg fixed w-full h-full bg-white" @transitionend.stop="progress += 1"></div>
    <div class="preloader-opacity fixed w-full h-full bg-white opacity-1" @transitionend.stop="progress += 1"></div>
    <div
      v-if="progress < 2"
      class="relative rotate-90 h-[100vw] flex px-1 bg-gray-300 box-content w-[28px] items-center"
    >
      <div class="logo-css"></div>
      <div
        class="preloader-veil-logo absolute bottom-0 top-0 right-0 w-0 bg-white"
        @transitionend.stop="progress = 2"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      progress: 0,
    }
  },
  computed: {
    isLoading() {
      return (
        this.$store.getters['entryLoaderScreen/hasPreloadScreen'] && this.$store.getters['resourceLoader/preloading']
      )
    },
  },
  watch: {
    progress() {
      // 0 — init
      // 1 - text veil start hiding, all resources loaded
      // 2 - text veil end hiding
      // 3 - opacity/bg blocks start hiding
      // 4 - opacity block end hiding
      // 5 - white bg block end hiding
      if (this.progress === 3) {
        this.$emit('after-logo-hide')
      }
    },
    isLoading() {
      this.progress = 1
    },
  },
}
</script>

<style lang="postcss" scoped>
.preloader {
  &-container {
    transition: opacity 0.2s ease;
    transition-delay: 1.2s;
  }

  &-bg {
    transition: transform 1.3s var(--cubic-bezier) 0.8s;
  }

  &-opacity {
    transition: opacity 1.1s var(--cubic-bezier) 0.8s;
  }

  &-veil-logo {
    transition: width 0.8s var(--cubic-bezier) 0.6s;
  }

  &-hide {
    opacity: 0.99; /* trigger Locomotive autoupdate handler */

    .preloader-bg {
      transform: translateY(-100vh);
    }

    .preloader-opacity {
      opacity: 0;
    }

    .preloader-veil-logo {
      width: 100%;
    }
  }
}
</style>
