<template>
  <input
    :id="id"
    :value="value"
    class="input w-full p-0 border-b outline-none bg-transparent appearance-none rounded-none"
    :class="{ [`${theme || 'white'}`]: true }"
    :type="type"
    :disabled="disabled"
    placeholder=" "
    @input="update($event.target.value)"
    @blur="$emit('blur')"
  />
</template>

<script>
export default {
  name: 'AtomInput',
  props: ['id', 'type', 'value', 'disabled', 'theme'],
  methods: {
    update(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="postcss" scoped>
.input {
  -webkit-appearance: none;
  &.dark {
    @apply text-gray-500 hover:text-white active:text-white hover:border-white active:border-white;
    &:hover {
      transition: border 1s var(--cubic-bezier);
      & ~ .placeholder {
        @apply text-white;
      }
    }
  }
  &.white {
    @apply border-gray-500 hover:border-gray-100;
  }
}
</style>
