<template>
  <div
    class="sub-link flex flex-col self-start md:px-2 lg:px-5 z-40 cursor-pointer"
    :class="nav.subLinkFixed"
    @mouseover="hovered"
    @mouseleave="isRotate = false"
  >
    <div class="nav-head z-20" :class="{ hovered: isRotate }">
      <AtomNavLinkHead :class="nav.class" :content="nav.title" :to="nav.to" :sub="hasSub" />
    </div>
    <div ref="subWrap" class="sub-nav-wrap">
      <div class="sub-nav-link relative flex flex-wrap container">
        <div v-for="link in nav.items" :key="link.name" class="flex flex-col md:w-1/4 pt-5">
          <span class="mb-3 text-gray-500">{{ link.title }}</span>
          <AtomNavLink
            v-for="item in link.links"
            :key="item.name"
            class="mb-3 last:mb-8"
            :content="item.title"
            :to="item.to"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomNavLink from '../../atoms/layouts/AtomNavLink'
import AtomNavLinkHead from '../../atoms/layouts/AtomNavLinkHead'

export default {
  name: 'MoleculeNavListLink',
  components: {
    AtomNavLinkHead,
    AtomNavLink,
  },
  props: ['nav'],
  data() {
    return {
      height: 0,
      isRotate: false,
    }
  },
  computed: {
    hasSub() {
      return Boolean(this.nav.items && this.nav.items.length > 0)
    },
  },
  methods: {
    hovered() {
      this.isRotate = true
      this.$emit('hovered', this.$refs.subWrap.clientHeight, this.hasSub)
    },
  },
}
</script>

<style lang="postcss">
.dark-theme {
  .nav-head {
    .link {
      @apply text-gray-100;
      &.tag .wrap-text {
        @apply border-gray-100;
      }
      .link-sub {
        svg {
          path {
            @apply stroke-gray-100;
          }
        }
      }
    }
  }
  .hover-nav {
    .nav-head {
      .link {
        @apply text-gray-100;
        svg {
          path {
            @apply stroke-gray-100;
          }
        }
      }
      &.open {
        a {
          @apply text-gray-100;
          svg {
            path {
              @apply stroke-gray-100;
            }
          }
        }
      }
    }
  }
}
.container-nav {
  &:before {
    /* @apply content-[''] bg-gray-500 left-5 right-5 h-[1px] top-[60px] fixed z-10 opacity-0; */
    /* transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
  }
  &:after {
    @apply content-[''] opacity-0;
    transition: opacity 0.3s cubic-bezier(0.37, 0, 0.63, 1);
  }
}
.container-nav-list {
  @apply md:min-h-[70px];
}
.sub-link {
  &.sub-fixed {
    .sub-nav-wrap {
      @apply left-auto;
    }
    .sub-nav-link {
      padding: 0;
    }
  }
  &.sub-not {
    .sub-nav-wrap {
      @apply !transition-none;
    }
  }
}
.hover-nav {
  @apply bg-white md:min-h-[70px];
  &:after {
    @apply fixed opacity-80 top-0 right-0 left-[-75px] bottom-0 bg-[#47474A] min-h-screen z-[-1];
  }
  /* &:before {
    @apply opacity-100 bg-gray-500 left-5 right-5 h-[1px] bottom-[1px] absolute z-10;
  } */
  .link {
    @apply text-gray-100;
    .wrap-text {
      @apply !border-gray-100;
    }
  }
  .link-sub {
    svg {
      path {
        @apply stroke-gray-100;
      }
    }
  }
  .link-sub {
    svg {
      path {
        @apply stroke-gray-100;
      }
    }
  }
  a {
    @apply text-gray-100;
    .wrap-text {
      @apply !border-white;
    }
  }
  .nav-head {
    .link {
      .box-text {
        &:before {
          transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
  .sub-link {
    @apply md:min-h-[70px];
    &:hover {
      .sub-nav-wrap {
        transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 1.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        visibility: visible;
        z-index: 3;
        pointer-events: auto;
        transform: translateY(0);
      }
      .sub-nav-wrap .sub-nav-link {
        transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateY(0);
        opacity: 1;
        transition-delay: 0.16s;
      }
      .box-text {
        &:before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
      .link.tag {
        @apply !py-0;
        .wrap-text {
          @apply pt-0 pb-0 border-0 px-[21px];
          .box-text {
            @apply pt-[23px] pb-[21px];
          }
        }
      }
    }
    &.tag-wrap {
      &:not(:hover) {
        .box-text {
          &:before {
            transition: transform 0s linear;
          }
        }
      }
    }
  }
}

.nav-head {
  .link {
    .box-text {
      &:before {
        content: '';
        position: absolute;
        bottom: -2px;
        right: 0;
        left: 0;
        height: 3px;
        background-color: #47474a;
        /* transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
        transform: scaleX(0);
        transform-origin: right center;
      }
    }
  }
  &.hovered {
    svg {
      @apply rotate-180;
    }
  }
}
.sub-nav-wrap {
  transition: visibility 0s linear;
  position: absolute;
  /* padding-top: 68px; */
  top: 100%;
  left: 0;
  width: 100%;
  visibility: hidden;
  will-change: background-color, transform;
  transform: translateY(0);
  pointer-events: none;
  .sub-nav-link {
    z-index: 2;
    transform: translateY(-20px);
    opacity: 0;
    will-change: transform, opacity;
    transition-delay: 0s;
  }
}
.nav {
  .link {
    &.tag {
      @apply pt-[17px] pb-[15px];
      .wrap-text {
        @apply border border-white rounded-[51px] py-[5px] px-5 relative;
        .box-text {
          @apply pt-0 pb-0;
          &:before {
            //transition: transform 0s linear;
          }
        }
      }
      /* &:hover {
        .box-text {
          &:before {
            transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      } */
    }
  }
}
</style>
