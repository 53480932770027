<template>
  <section class="footer-nav-last bg-gray-100 md:border-l border-l-gray-300">
    <div class="container">
      <div
        class="list-link grid grid-cols-2 items-start -md:items-baseline gap-2 md:flex justify-between bg-gray-100 border-t pt-3 pb-16 md:pt-6 md:pb-7"
      >
        <template v-for="link in links">
          <AtomLinkLocale
            v-if="link.locale"
            :key="link.name"
            :to="link.to"
            :content="link.content"
            :class="link.class"
            class="text-gray-400 fade-item"
          />
          <AtomLinkItem
            v-else
            :key="link.name"
            :to="link.to"
            :href="link.href"
            :content="link.content"
            :class="link.class"
            class="text-gray-400 fade-item"
          />
        </template>
        <div class="-md:order-3">
          <template v-for="link in linksGroups">
            <div :key="link.name" class="text-right">
              <AtomLinkItem
                :key="link.name"
                :to="link.to"
                :href="link.href"
                :content="link.content"
                :class="link.class"
                class="text-gray-400 fade-item"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AtomLinkItem from '../../atoms/AtomLinkItem'
import AtomLinkLocale from '../../atoms/AtomLinkLocale'

export default {
  name: 'MoleculeFooterListLink',
  components: {
    AtomLinkItem,
    AtomLinkLocale,
  },
  props: ['links', 'linksGroups'],
}
</script>

<style lang="postcss" scoped>
.footer-nav-last {
  &:after {
    @apply content-[''] bg-gray-100 h-1 absolute bottom-[-1px] left-0 right-0 w-full;
  }
}
</style>
