export const state = () => ({})

export const mutations = {}

export const actions = {
  async nuxtServerInit({dispatch}, {req}) {
    await dispatch('modules/content/setLocale')
    await Promise.all([
      dispatch('modules/content/getContent', {
        id: 'global',
      }),
      dispatch('modules/content/getContent', {
        id: 'city/ip',
        headers: {
          'X-Real-IP': req.headers['x-real-ip'] || '',
          'X-Forwarded-For': req.headers['x-forwarded-for'] || ''
        }
      }),
      dispatch('modules/content/getContent', {
        id: 'clubs'
      })
    ])
  },
}
