<template>
  <SectionDefault class="-md:!z-30" z="20">
    <div data-scroll data-scroll-id="footer_pin" class="overflow-hidden bg-gray-100 print:!hidden">
      <div>
        <MoleculeFooterLinks v-if="links.length" :footer-links="links" class="dark" />
        <MoleculeFooterApps class="dark" />
        <FooterNav />
        <FooterForms />
        <FooterLastNav />
      </div>
    </div>
  </SectionDefault>
</template>

<script>
import MoleculeFooterApps from '../../molecules/layouts/MoleculeFooterApps'
import MoleculeFooterLinks from '../../molecules/layouts/MoleculeFooterLinks'
import FooterForms from '../../templates/layouts/FooterForms'
import FooterLastNav from '../../templates/layouts/FooterLastNav'
import FooterNav from '../../templates/layouts/FooterNav'
import SectionDefault from '../../templates/layouts/SectionDefault'

export default {
  components: {
    FooterNav,
    FooterForms,
    FooterLastNav,
    MoleculeFooterLinks,
    MoleculeFooterApps,
    SectionDefault,
  },
  computed: {
    links() {
      const page = this.$store.getters['modules/content/page']
      if (!page) return []
      const links = (
        page.footer
          ? [
              {
                to: page.footer.url1,
                content: page.footer.button1,
              },
              {
                to: page.footer.url2,
                content: page.footer.button2,
              },
            ]
          : []
      ).filter(l => l.to)
      return links
    },
  },
  mounted() {
    this.$root.$loco.on('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$root.$loco.off('scroll', this.handleScroll)
  },
  methods: {
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
    handleScroll(data) {
      if (!this.isDesktop()) return
      const pin = data.currentElements.footer_pin
      if (pin) {
        const box = pin.el.getBoundingClientRect()
        const transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-box.y.toFixed(2)}, 0, 1)`
        pin.el.firstChild.style.transform = box.y > 0 ? transform : 'none'
      }
    },
  },
}
</script>
