export const getImage = (src, { modifiers } = {}) => {
  const breakpoint = (modifiers && modifiers.breakpoint) || null

  if (!breakpoint) {
    return {
      url: src,
    }
  }

  const paths = src.split('/')
  paths[paths.length - 1] = `${breakpoint}_${paths[paths.length - 1]}`

  return {
    url: paths.join('/'),
  }
}
