export default function (context) {
  const { app, store } = context
  app.router.beforeEach((to, from, next) => {
    const isFirstEnter = !from.name
    const canNavigate = store.getters['pageTransition/canNavigate']
    if (isFirstEnter || canNavigate) {
      const betweenChildToChild = to.matched.length > 1 &&
        from.matched.length > 1 &&
        to.matched[0].path === from.matched[0].path
      const betweenParentToChild = to.matched.length > 1 &&
        from.matched.length === 1 &&
        to.matched[0].name === from.matched[0].name
      const isHashNavigation = from.path === to.path
        && from.hash !== to.hash
      const onlyQueryChanged = from.path === to.path
        && !isObjectsEqual(from.query, to.query)
      if (
        (betweenChildToChild || betweenParentToChild)
        && to.hash === from.hash
      ) {
        store.dispatch('pageTransition/skip')
      }
      if (!isFirstEnter && !betweenChildToChild && !isHashNavigation && !onlyQueryChanged) {
        process.client && store.dispatch('scrollBlocker/block', 'routing', { root: true })
      }
      next()
    } else {
      next(false)
    }
  })
  app.router.afterEach(() => {
    process.client && setTimeout(() => {
      store.dispatch('pageTransition/unskip')
    })
  })
  app.router.onError((e) => {
    store.dispatch('scrollBlocker/unblock', 'routing', { root: true })
  })
}

function isObjectsEqual(a = {}, b = {}) {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  return aKeys.length === bKeys.length
    && aKeys.every((key) => a[key] == b[key])
}
