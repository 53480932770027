export default function ({ app }) {
  document.body.addEventListener('click', e => {
    if (e.target && e.target.hasAttribute('data-yclients-url')) {
      window.yWidget && window.yWidget.show(e.target.getAttribute('data-yclients-url'))
    }
  })
  app.router.beforeEach((_, __, next) => {
    window.yWidget && window.yWidget.block && window.yWidget.hide()
    next()
  })
}
