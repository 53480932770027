export const canonical = (path = '', siteUrl = '') => {
  return [
    {
      rel: 'canonical',
      href: siteUrl + path,
    },
  ]
}

export const facebook = (path = [], seo = {}, globals = {}, siteUrl = '') => {
  if (shouldAssignStaticMeta(path)) {
    seo = staticData[path]
  }
  const ogImage = seo.ogImage || globals.ogImage
  const imageUrl = ogImage ? ogImage.url : require('~/assets/images/og.jpg')

  return [
    {
      hid: 'og:title',
      property: 'og:title',
      content: seo.ogTitle || globals.ogTitle || globals.metaTitle,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: seo.ogDescription || globals.ogDescription || globals.metaDescription,
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: seo.ogType || globals.ogType,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: seo.ogSite || globals.ogSite,
    },
    {
      hid: 'og:locale',
      property: 'og:locale',
      content: seo.ogLocale || globals.ogLocale,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: seo.ogUrl || globals.ogUrl,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: siteUrl + imageUrl,
    },
  ]
}

export const twitter = (path = [], seo = {}, globals = {}, siteUrl = '') => {
  if (shouldAssignStaticMeta(path)) {
    seo = staticData[path]
  }
  const ogImage = seo.ogImage || globals.ogImage
  const imageUrl = ogImage ? ogImage.url : require('~/assets/images/og.jpg')

  return [
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: seo.ogTitle || globals.ogTitle || globals.metaTitle,
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: seo.ogDescription || globals.ogDescription || globals.metaDescription,
    },
    {
      hid: 'twitter:image:src',
      name: 'twitter:image:src',
      content: siteUrl + imageUrl,
    },
  ]
}

export const seo = (path = [], seo = {}, globals = {}) => {
  if (shouldAssignStaticMeta(path)) {
    seo = staticData[path]
  }
  const ogVideo = seo.ogVideo || globals.ogVideo

  return [
    {
      hid: 'title',
      name: 'title',
      content: seo.metaTitle || globals.metaTitle,
    },
    {
      hid: 'description',
      name: 'description',
      content: seo.metaDescription || globals.metaDescription,
    },
    {
      hid: 'og:video',
      name: 'og:video',
      content: ogVideo ? ogVideo.url : '',
    },
  ]
}

export const title = (path, seo = {}, globals = {}) => {
  if (shouldAssignStaticMeta(path)) {
    seo = staticData[path]
  }
  return seo.metaTitle || globals.metaTitle
}

export const appLdJson = (path = [], siteUrl = '') => {
  return [
    {
      hid: 'application/ld+json',
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'website',
        name: 'Encore Fitness',
        url: siteUrl + path,
        sameAs: ['https://www.facebook.com/', 'https://www.instagram.com/',
          'https://www.twitter.com/', 'https://vk.com/'
        ],
      },
    },
  ]
}

function shouldAssignStaticMeta(path = '') {
  return path in staticData
}

const staticData = {
  // adult
  '/timetable/encore_city/adult': {
    metaTitle: 'Расписание в Encore Сити для взрослых',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Сити для взрослых'
  },
  '/timetable/encore_yasenevo/adult': {
    metaTitle: 'Расписание в Encore Ясенево для взрослых',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Ясенево для взрослых'
  },
  '/timetable/encore_khodynka/adult': {
    metaTitle: 'Расписание в Encore Ходынка для взрослых',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Ходынка для взрослых'
  },
  '/timetable/encore_vasilevskiy/adult': {
    metaTitle: 'Расписание в Encore Васильевский для взрослых',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Васильевский для взрослых'
  },
  '/timetable/encore_iset/adult': {
    metaTitle: 'Расписание в Encore Исеть для взрослых',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Исеть для взрослых'
  },
  '/timetable/encore_sochi/adult': {
    metaTitle: 'Расписание в Encore Сочи для взрослых',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Сочи для взрослых'
  },

  // kids
  '/timetable/encore_yasenevo/kids': {
    metaTitle: 'Расписание в Encore Ясенево для детей',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Ясенево для детей'
  },
  '/timetable/encore_khodynka/kids': {
    metaTitle: 'Расписание в Encore Ходынка для детей',
    ogDescription: 'Encore – воплощение ваших представлений об идеальном фитнес-клубе. Расписание в Encore Ходынка для детей'
  },

  // spa
  '/spa/encore_city': {
    metaTitle: 'Спа в Москва - Сити | Encore Spa',
    ogDescription: 'Encore Spa — новейшие и самые эффективные методики по эстетике лица и тела. Большая массажная карта, аппаратная косметология, спа-программы, услуги парикмахеров и стилистов, маникюр и многое другое.'
  },
}
