<template>
  <a
    v-if="to && to.startsWith('http')"
    :href="to"
    target="_blank"
    class="link-item inline-flex relative text-gray-300 hover:text-gray-100 text-base overflow-hidden"
  >
    <span class="span">{{ content }}</span>
    <span class="span">{{ content }}</span>
  </a>
  <NuxtLink
    v-else
    class="link-item inline-flex relative text-gray-300 hover:text-gray-100 text-base overflow-hidden"
    :to="to"
  >
    <span class="span">{{ content }}</span>
    <span class="span">{{ content }}</span>
  </NuxtLink>
</template>

<script>
export default {
  name: 'AtomNavLink',
  props: ['content', 'to', 'target'],
}
</script>

<style lang="postcss" scoped>
.link-item {
  &:hover {
    .span:nth-of-type(1) {
      transform: translateY(-200%) rotate(-10deg);
      transition-delay: 0s;
    }
    .span:nth-of-type(2) {
      transform: translateY(0%) rotate(0.001deg);
      transition-delay: -0.05s;
    }
    &:not(.dark) {
      .span:nth-of-type(2) {
        @apply text-gray-400;
      }
    }
  }
  &.disabled span {
    @apply !text-gray-500;
    transform: none !important;
  }
}
.span {
  @apply block text-gray-100 hover:text-gray-400;
  &:nth-of-type(1) {
    transform: translateY(0%) rotate(0.001deg);
    transition: var(--animation-primary);
    white-space: nowrap;
    transition-delay: -0.05s;
    position: relative;
  }
  &:nth-of-type(2) {
    @apply absolute top-0 left-0;
    white-space: nowrap;
    transform: translateY(200%) rotate(10deg);
    transition: var(--animation-primary);
    transition-delay: 0s;
  }
}
</style>
