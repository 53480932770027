import Vue from 'vue'

Vue.mixin({
  methods: {
    ensureArray(value) {
      if (Array.isArray(value)) {
        return value
      } else if (value === undefined || value === null) {
        return []
      } else {
        return [value]
      }
    },
  },
})
