<template>
  <div ref="container" class="w-full" data-scroll-container>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SmoothScroll',
  mounted() {
    this.$refs.container.setAttribute('scroll-context', this.$root.$loco.scroll.context)
  },
}
</script>
