import dayjs from 'dayjs'

import 'dayjs/locale/ru'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)

dayjs.locale('ru')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
