import { render, staticRenderFns } from "./Scroll.vue?vue&type=template&id=f9856576&scoped=true&"
import script from "./Scroll.vue?vue&type=script&lang=js&"
export * from "./Scroll.vue?vue&type=script&lang=js&"
import style0 from "./Scroll.vue?vue&type=style&index=0&id=f9856576&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9856576",
  null
  
)

export default component.exports