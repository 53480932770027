<template>
  <label class="text-sm md:text-base flex items-baseline" :for="idFor">
    <slot />
    <span v-html="label"></span>
  </label>
</template>

<script>
export default {
  name: 'AtomLabel',
  props: ['idFor', 'label'],
}
</script>
