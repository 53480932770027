<template>
  <div class="list-link flex flex-col self-start bg-gray-100">
    <AtomLinkItem
      v-for="link in links"
      :key="link.to"
      :to="link.to"
      :content="link.title"
      :class="link.class"
      class="fade-item"
    />
  </div>
</template>

<script>
import AtomLinkItem from '../../atoms/AtomLinkItem'

export default {
  name: 'MoleculeFooterListLink',
  components: {
    AtomLinkItem,
  },
  props: ['links'],
}
</script>
