<template>
  <div>
    <section class="relative w-full min-h-[100svh] md:max-h-[100svh] print:min-h-0 sd bg-white">
      <div>
        <div ref="pin">
          <div class="hero-wrap md:pr-5 !border-gray-100 md:pl-0">
            <section
              class="min-h-[100svh] flex pt-[56px] md:pt-20 md:pb-28 print:min-h-0 print:inline-block print:!pt-0"
            >
              <a href="/" class="absolute top-4 left-4 z-10 py-1 md:hidden">
                <svg width="113" height="15" viewBox="0 0 113 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M72.4075 6.67842H71.2627V6.05833H72.4075V4.62735C72.4075 3.00556 73.3138 2.24237 74.6494 2.24237C74.9356 2.24237 75.2218 2.29007 75.508 2.33777V3.05326C75.2695 2.95786 74.9833 2.91016 74.6971 2.95786C73.8385 2.95786 73.2661 3.48256 73.2661 4.57965V6.01063L75.4603 6.01063V6.72612L73.2661 6.72612V13.881H72.4552L72.4075 6.67842Z"
                    fill="#282929"
                  />
                  <path d="M76.7958 6.05833H77.6067V13.881H76.8435L76.7958 6.05833Z" fill="#282929" />
                  <path
                    d="M80.0871 12.0208V6.67842L78.9423 6.67842V6.05833H80.0871V4.29345H80.898V6.05833H82.9491V6.77382H80.898V12.0208C80.898 12.9271 81.375 13.4518 82.0428 13.4518C82.3767 13.4518 82.7106 13.3564 83.0445 13.261V13.9287C82.7106 14.0241 82.329 14.1195 81.9951 14.1195C80.8026 13.9764 80.0871 13.261 80.0871 12.0208Z"
                    fill="#282929"
                  />
                  <path
                    d="M85.334 6.05833V7.58472C85.8587 6.53533 86.9558 5.91523 88.1006 5.96293C89.7701 5.96293 90.8672 6.86922 90.8672 9.0634V13.881H90.0563V9.0157C90.0563 7.34622 89.2931 6.63073 87.9098 6.63073C86.5742 6.58303 85.4294 7.58472 85.2863 8.968V13.881H84.4755V6.05833H85.334Z"
                    fill="#282929"
                  />
                  <path
                    d="M92.4413 10.0651V9.96969C92.4413 7.58471 93.9677 5.96293 96.0664 5.96293C97.9267 5.96293 99.5008 7.20312 99.5008 9.77889V10.0651H93.2522C93.3476 12.1639 94.3015 13.3564 96.1618 13.3564C97.402 13.3564 98.356 12.8317 98.5945 11.7346L99.4054 11.7346C99.0715 13.261 97.8313 14.0718 96.1141 14.0718C93.8722 14.0241 92.4413 12.4024 92.4413 10.0651ZM98.6422 9.3496C98.5468 7.44162 97.5451 6.58303 96.0187 6.58303C94.4924 6.58303 93.3953 7.68011 93.2522 9.3496H98.6422Z"
                    fill="#282929"
                  />
                  <path
                    d="M100.407 11.5438H101.17C101.266 12.5932 101.981 13.3087 103.46 13.3087C104.795 13.3087 105.416 12.7363 105.416 11.7346C105.416 10.7329 104.843 10.399 103.364 10.1605C101.456 9.82659 100.693 9.3496 100.693 7.96631C100.693 6.58302 101.933 5.86753 103.269 5.86753C104.7 5.86753 105.749 6.43993 106.036 8.01401H105.272C105.082 7.01232 104.462 6.58303 103.317 6.58303C102.172 6.58303 101.552 7.15542 101.552 8.01401C101.552 8.8249 102.077 9.2542 103.698 9.54039C105.32 9.82659 106.322 10.2559 106.322 11.7346C106.322 13.0702 105.32 14.0241 103.555 14.0241C101.647 13.9764 100.55 13.0702 100.407 11.5438Z"
                    fill="#282929"
                  />
                  <path
                    d="M107.085 11.5438H107.848C107.944 12.5932 108.659 13.3087 110.138 13.3087C111.473 13.3087 112.093 12.7363 112.093 11.7346C112.093 10.7329 111.521 10.399 110.042 10.1605C108.134 9.82659 107.371 9.3496 107.371 7.96631C107.371 6.58302 108.611 5.86753 109.947 5.86753C111.378 5.86753 112.427 6.43993 112.714 8.01401H111.95C111.76 7.01232 111.139 6.58303 109.995 6.58303C108.85 6.58303 108.23 7.15542 108.23 8.01401C108.23 8.8249 108.755 9.2542 110.376 9.54039C111.998 9.82659 113 10.2559 113 11.7346C113 13.0702 111.998 14.0241 110.233 14.0241C108.325 13.9764 107.228 13.0702 107.085 11.5438Z"
                    fill="#282929"
                  />
                  <path
                    d="M77.2728 4.38885C77.589 4.38885 77.8452 4.13258 77.8452 3.81645C77.8452 3.50033 77.589 3.24406 77.2728 3.24406C76.9567 3.24406 76.7004 3.50033 76.7004 3.81645C76.7004 4.13258 76.9567 4.38885 77.2728 4.38885Z"
                    fill="#282929"
                  />
                  <path
                    d="M68.258 7.01183C68.258 3.10047 65.2052 0 61.2939 0C57.2394 0 54.2343 3.19587 54.2343 7.20262C54.2343 11.2094 57.3348 14.3098 61.3415 14.3098C64.2035 14.3098 66.9701 12.3542 67.8287 9.6353H64.442C63.7742 10.8278 62.6294 11.3525 61.3415 11.3525C60.006 11.3525 58.8135 10.6847 58.0503 9.5399C57.8595 9.2537 57.6687 8.9198 57.621 8.58591L68.0672 8.58591C68.2103 8.10891 68.258 7.53652 68.258 7.01183ZM57.5256 6.01014C58.0026 4.10216 59.3859 2.90967 61.3415 2.90967C63.2972 2.90967 64.6805 4.05446 64.9667 6.01014L57.5256 6.01014Z"
                    fill="#282929"
                  />
                  <path
                    d="M55.3314 0C53.7096 0 53.0895 0.333896 52.1832 1.66948H52.1356V0.429296L49.1782 0.429296V13.8806L52.4217 13.8806V6.34403C52.4217 4.38835 53.2326 3.19587 55.3314 3.10047V0Z"
                    fill="#282929"
                  />
                  <path
                    d="M42.071 0C38.1119 0 34.916 3.24357 34.916 7.20262C34.916 11.114 38.1596 14.3575 42.071 14.3575C46.1254 14.3575 49.1782 11.2094 49.1782 7.15492C49.1782 3.19587 46.03 0 42.071 0ZM42.071 11.4002C39.7337 11.4002 38.1119 9.4445 38.1119 7.20262C38.1119 5.00845 39.7814 2.95737 42.071 2.95737C44.3605 2.95737 45.9823 5.05615 45.9823 7.20262C45.9823 9.3968 44.3605 11.4002 42.071 11.4002Z"
                    fill="#282929"
                  />
                  <path
                    d="M28.572 11.4002C26.187 11.4002 24.5175 9.4922 24.5175 7.20262C24.5175 4.91305 26.0439 3.00507 28.4289 3.00507C29.9076 3.00507 30.8616 3.72056 31.5771 4.96075H35.2022C34.2005 2.00338 31.5771 0.0477006 28.4289 0.0477006C24.5175 0.0477006 21.3217 3.24357 21.3217 7.15492C21.3217 11.114 24.5175 14.3575 28.4766 14.3575C31.6725 14.3575 34.1528 12.4973 35.2022 9.5399H31.5771C30.8616 10.5893 29.8599 11.4002 28.572 11.4002Z"
                    fill="#282929"
                  />
                  <path
                    d="M20.0815 1.86028C19.1275 0.715493 17.5057 0 16.027 0C14.5006 0 13.4036 0.572395 12.3542 1.62178V0.429296L6.29633 0.429296C8.9198 1.43099 10.7324 3.95906 10.7324 6.96413C10.7324 7.48882 10.637 8.06121 10.5416 8.58591L0.0953975 8.58591C0.190797 8.9675 0.333896 9.2537 0.524694 9.5399C1.24019 10.637 2.48037 11.3525 3.81596 11.3525C5.10385 11.3525 6.24863 10.8278 6.91643 9.6353H10.3031C9.683 11.591 8.06121 13.1651 6.15323 13.8806H12.545V7.48882C12.545 6.48713 12.545 4.96075 13.1174 4.10216C13.5944 3.38666 14.4529 2.90967 15.3115 2.90967C17.6965 2.90967 18.0304 5.34234 18.0304 7.20262L18.0304 13.8329H21.274L21.274 6.43943C21.274 4.86535 21.0832 3.10047 20.0815 1.86028Z"
                    fill="#282929"
                  />
                  <path
                    d="M3.81596 2.95737C1.86028 2.95737 0.476995 4.10216 0 6.05784L7.44112 6.05784C7.15492 4.10216 5.77164 2.95737 3.81596 2.95737Z"
                    fill="#282929"
                  />
                </svg>
              </a>
              <AtomImage
                class="absolute right-0 bottom-0 object-cover object-right min-h-full w-full -md:!hidden"
                :src="hero.image"
              />
              <AtomImage
                v-if="hero.imageMobile"
                :src="hero.imageMobile"
                img-classes="w-full scale-100"
                class="absolute inset-0 min-h-full md:!hidden object-cover object-right"
              />
              <div class="container md:py-8 flex md:items-start print:!pl-0">
                <div class="flex flex-col w-full">
                  <div class="flex flex-col relative -md:border-t -md:pt-3">
                    <div class="grid md:grid-cols-12 md:pb-2">
                      <div class="hidden md:block pr-3 md:col-span-2">
                        <AtomBreadcrumb
                          v-for="breadcrumb in hero.breadcrumbs"
                          :key="breadcrumb.key"
                          :to="breadcrumb.to"
                          :href="breadcrumb.href"
                          :content="breadcrumb.title"
                        />
                      </div>
                      <div class="col-span-10">
                        <AtomTitle class-name="print:!text-[14px]" :content="hero.title1" />
                      </div>
                    </div>
                    <AtomHR class="z-[1] w-full hidden md:block print:!hidden" />
                    <div class="grid md:grid-cols-12 pt-[2px] md:pt-5 print:!hidden">
                      <div class="md:col-span-10 md:col-start-3">
                        <div class="md:grid grid-cols-10 justify-between md:mt-5">
                          <AtomTitle :class="{ '!col-span-7': hero.desc }" class="col-span-10" :content="hero.title2" />
                          <AtomHR class="md:hidden w-full mt-2" />
                          <!-- <AtomText
                            v-if="hero.desc"
                            class="mt-3 md:mt-0 col-span-3"
                            :class="{ '!text-white': !isDark, '-md:hidden': hero.hideDesc }"
                            :content="hero.desc"
                            animation="fade"
                          /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="absolute z-10 bottom-3 md:bottom-0 left-3 right-3 md:right-5 md:pl-5 md:h-[110px]">
              <div class="-md:flex flex-col md:grid grid-cols-12 h-full pb-4 md:pb-8">
                <div class="md:col-span-10 md:col-start-3 flex flex-1">
                  <AtomButton tag="a" href="/" target="_self" class="self-end -md:order-1">НА ГЛАВНУЮ</AtomButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 bg-white left-[75px] hidden md:block z-10 !w-0" />
    </section>
    <!-- <h1 v-if="error.statusCode === 404">Cтраница не&nbsp;найдена</h1>
    <h1 v-else>An error occurred</h1>
    <a href="/">BACK TO HOME</a> -->
  </div>
</template>

<script>
import AtomImage from '../components/atoms/AtomImage'
import AtomTitle from '../components/atoms/AtomTitle'
import AtomHR from '../components/atoms/AtomHR'
import AtomBreadcrumb from '../components/atoms/layouts/AtomBreadcrumb'
import AtomButton from '../components/atoms/AtomButton'

export default {
  components: {
    AtomImage,
    AtomTitle,
    AtomHR,
    AtomBreadcrumb,
    AtomButton,
  },
  layout: 'errorLayout',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data: c => {
    return {
      hero: {
        variant: 'dark-parallax',
        title1: 'Cтраница',
        title2: 'не&nbsp;найдена',
        image: 'error/hero.jpg',
        imageMobile: 'error/hero-mobile.jpg',
        breadcrumbs: [
          {
            title: 'Главная',
            href: '/',
          },
          {
            title: '404',
          },
        ],
      },
    }
  },

  head() {
    return {
      title: 'Cтраница не найдена',
    }
  },
}
</script>
