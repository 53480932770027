<template>
  <div>
    <section
      ref="nav"
      class="nav-mobile fixed max-h-screen overflow-auto top-0 left-0 bottom-0 right-0 z-40 bg-gray-100"
      :class="{ open: showMenu }"
    >
      <div class="container">
        <div class="flex flex-col pb-24">
          <div class="flex flex-row justify-between border-b border-gray-300 py-[0.95rem] items-center md:my-[-6px]">
            <NuxtLink to="/">
              <svg width="113" height="15" viewBox="0 0 113 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M72.4077 6.67836H71.2629V6.05827H72.4077V4.62728C72.4077 3.0055 73.314 2.24231 74.6496 2.24231C74.9358 2.24231 75.222 2.29001 75.5082 2.33771V3.0532C75.2697 2.9578 74.9835 2.9101 74.6973 2.9578C73.8387 2.9578 73.2663 3.4825 73.2663 4.57959V6.01057L75.4605 6.01057V6.72606L73.2663 6.72606V13.881H72.4554L72.4077 6.67836Z"
                  fill="#76767A"
                />
                <path d="M76.7961 6.05827H77.607V13.881H76.8438L76.7961 6.05827Z" fill="#76767A" />
                <path
                  d="M80.0873 12.0207V6.67836L78.9426 6.67836V6.05827H80.0873V4.29339H80.8982V6.05827H82.9493V6.77376H80.8982V12.0207C80.8982 12.927 81.3752 13.4517 82.043 13.4517C82.3769 13.4517 82.7108 13.3563 83.0447 13.2609V13.9287C82.7108 14.0241 82.3292 14.1195 81.9953 14.1195C80.8028 13.9764 80.0873 13.2609 80.0873 12.0207Z"
                  fill="#76767A"
                />
                <path
                  d="M85.3343 6.05827V7.58465C85.859 6.53527 86.9561 5.91517 88.1009 5.96287C89.7703 5.96287 90.8674 6.86916 90.8674 9.06334V13.881H90.0565V9.01564C90.0565 7.34616 89.2934 6.63066 87.9101 6.63066C86.5745 6.58297 85.4297 7.58466 85.2866 8.96794V13.881H84.4757V6.05827H85.3343Z"
                  fill="#76767A"
                />
                <path
                  d="M92.4415 10.065V9.96963C92.4415 7.58465 93.9679 5.96287 96.0667 5.96287C97.927 5.96287 99.501 7.20306 99.501 9.77883V10.065H93.2524C93.3478 12.1638 94.3018 13.3563 96.1621 13.3563C97.4023 13.3563 98.3563 12.8316 98.5947 11.7345L99.4056 11.7345C99.0717 13.2609 97.8316 14.0718 96.1144 14.0718C93.8725 14.0241 92.4415 12.4023 92.4415 10.065ZM98.6425 9.34954C98.5471 7.44156 97.5454 6.58296 96.019 6.58296C94.4926 6.58296 93.3955 7.68005 93.2524 9.34954H98.6425Z"
                  fill="#76767A"
                />
                <path
                  d="M100.407 11.5437H101.171C101.266 12.5931 101.981 13.3086 103.46 13.3086C104.796 13.3086 105.416 12.7362 105.416 11.7345C105.416 10.7328 104.843 10.3989 103.365 10.1604C101.457 9.82653 100.694 9.34953 100.694 7.96625C100.694 6.58296 101.934 5.86747 103.269 5.86747C104.7 5.86747 105.75 6.43987 106.036 8.01395H105.273C105.082 7.01226 104.462 6.58296 103.317 6.58296C102.172 6.58296 101.552 7.15536 101.552 8.01395C101.552 8.82484 102.077 9.25414 103.699 9.54033C105.32 9.82653 106.322 10.2558 106.322 11.7345C106.322 13.0701 105.32 14.0241 103.555 14.0241C101.648 13.9764 100.55 13.0701 100.407 11.5437Z"
                  fill="#76767A"
                />
                <path
                  d="M107.085 11.5437H107.848C107.944 12.5931 108.659 13.3086 110.138 13.3086C111.474 13.3086 112.094 12.7362 112.094 11.7345C112.094 10.7328 111.521 10.3989 110.043 10.1604C108.135 9.82653 107.371 9.34953 107.371 7.96625C107.371 6.58296 108.612 5.86747 109.947 5.86747C111.378 5.86747 112.428 6.43987 112.714 8.01395H111.951C111.76 7.01226 111.14 6.58296 109.995 6.58296C108.85 6.58296 108.23 7.15536 108.23 8.01395C108.23 8.82484 108.755 9.25414 110.377 9.54033C111.998 9.82653 113 10.2558 113 11.7345C113 13.0701 111.998 14.0241 110.233 14.0241C108.325 13.9764 107.228 13.0701 107.085 11.5437Z"
                  fill="#76767A"
                />
                <path
                  d="M77.2731 4.38879C77.5892 4.38879 77.8455 4.13252 77.8455 3.81639C77.8455 3.50027 77.5892 3.244 77.2731 3.244C76.957 3.244 76.7007 3.50027 76.7007 3.81639C76.7007 4.13252 76.957 4.38879 77.2731 4.38879Z"
                  fill="#76767A"
                />
                <path
                  d="M68.258 7.01183C68.258 3.10047 65.2052 0 61.2939 0C57.2394 0 54.2343 3.19587 54.2343 7.20262C54.2343 11.2094 57.3348 14.3098 61.3415 14.3098C64.2035 14.3098 66.9701 12.3542 67.8287 9.6353H64.442C63.7742 10.8278 62.6294 11.3525 61.3415 11.3525C60.006 11.3525 58.8135 10.6847 58.0503 9.5399C57.8595 9.2537 57.6687 8.9198 57.621 8.58591L68.0672 8.58591C68.2103 8.10891 68.258 7.53652 68.258 7.01183ZM57.5256 6.01014C58.0026 4.10216 59.3859 2.90967 61.3415 2.90967C63.2972 2.90967 64.6805 4.05446 64.9667 6.01014L57.5256 6.01014Z"
                  fill="#76767A"
                />
                <path
                  d="M55.3314 0C53.7096 0 53.0895 0.333896 52.1832 1.66948H52.1356V0.429296L49.1782 0.429296V13.8806L52.4217 13.8806V6.34403C52.4217 4.38835 53.2326 3.19587 55.3314 3.10047V0Z"
                  fill="#76767A"
                />
                <path
                  d="M42.071 0C38.1119 0 34.916 3.24357 34.916 7.20262C34.916 11.114 38.1596 14.3575 42.071 14.3575C46.1254 14.3575 49.1782 11.2094 49.1782 7.15492C49.1782 3.19587 46.03 0 42.071 0ZM42.071 11.4002C39.7337 11.4002 38.1119 9.4445 38.1119 7.20262C38.1119 5.00845 39.7814 2.95737 42.071 2.95737C44.3605 2.95737 45.9823 5.05615 45.9823 7.20262C45.9823 9.3968 44.3605 11.4002 42.071 11.4002Z"
                  fill="#76767A"
                />
                <path
                  d="M28.572 11.4002C26.187 11.4002 24.5175 9.4922 24.5175 7.20262C24.5175 4.91305 26.0439 3.00507 28.4289 3.00507C29.9076 3.00507 30.8616 3.72056 31.5771 4.96075H35.2022C34.2005 2.00338 31.5771 0.0477006 28.4289 0.0477006C24.5175 0.0477006 21.3217 3.24357 21.3217 7.15492C21.3217 11.114 24.5175 14.3575 28.4766 14.3575C31.6725 14.3575 34.1528 12.4973 35.2022 9.5399H31.5771C30.8616 10.5893 29.8599 11.4002 28.572 11.4002Z"
                  fill="#76767A"
                />
                <path
                  d="M20.0815 1.86028C19.1275 0.715493 17.5057 0 16.027 0C14.5006 0 13.4036 0.572395 12.3542 1.62178V0.429296L6.29633 0.429296C8.9198 1.43099 10.7324 3.95906 10.7324 6.96413C10.7324 7.48882 10.637 8.06121 10.5416 8.58591L0.0953975 8.58591C0.190797 8.9675 0.333896 9.2537 0.524694 9.5399C1.24019 10.637 2.48037 11.3525 3.81596 11.3525C5.10385 11.3525 6.24863 10.8278 6.91643 9.6353H10.3031C9.683 11.591 8.06121 13.1651 6.15323 13.8806H12.545V7.48882C12.545 6.48713 12.545 4.96075 13.1174 4.10216C13.5944 3.38666 14.4529 2.90967 15.3115 2.90967C17.6965 2.90967 18.0304 5.34234 18.0304 7.20262L18.0304 13.8329H21.274L21.274 6.43943C21.274 4.86535 21.0832 3.10047 20.0815 1.86028Z"
                  fill="#76767A"
                />
                <path
                  d="M3.81596 2.95737C1.86028 2.95737 0.476995 4.10216 0 6.05784L7.44112 6.05784C7.15492 4.10216 5.77164 2.95737 3.81596 2.95737Z"
                  fill="#76767A"
                />
              </svg>
            </NuxtLink>
            <div class="flex flex-row gap-x-2 mt-[-3px] mb-[-6px]">
              <AtomButton
                v-for="item in mobileHeadLink"
                :key="item.name"
                :href="item.to"
                tag="a"
                class="!text-gray-400 !text-sm !pl-1 !pr-0"
                >{{ item.title }}</AtomButton
              >
            </div>
          </div>
          <div class="flex flex-col">
            <MoleculeNavMobileListLink v-for="nav in menu" :key="nav.name" :nav="nav" />
          </div>
        </div>
      </div>
    </section>
    <div class="fixed z-[45] bottom-10 w-full flex justify-center print:!hidden pointer-events-none">
      <div
        ref="dropdownRoot"
        class="flex flex-row items-center justify-center bg-gray-100 rounded-[56px] shadow-[0_10px_20px_rgba(0,0,0,0.2)] px-2 pointer-events-auto"
      >
        <div class="inline-flex cursor-pointer" @click="showMenu = !showMenu">
          <button class="burger-mobile" :class="{ open: showMenu }">
            <span />
            <span />
            <span />
            <span />
          </button>
        </div>
        <div class="px-3 py-4" @click="dropdown = 'timetable'">
          <svg width="22" height="22" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.916687 5.06577C0.916687 4.78962 1.14054 4.56577 1.41669 4.56577H15.5834C15.8595 4.56577 16.0834 4.78962 16.0834 5.06577C16.0834 5.34191 15.8595 5.56577 15.5834 5.56577H1.41669C1.14054 5.56577 0.916687 5.34191 0.916687 5.06577Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.35129 1.99339C1.79165 2.57979 1.5 3.45112 1.5 4.58738V11.4793C1.5 12.6155 1.79165 13.4869 2.35129 14.0733C2.90676 14.6553 3.7997 15.0333 5.16667 15.0333H11.8333C13.2003 15.0333 14.0932 14.6553 14.6487 14.0733C15.2083 13.4869 15.5 12.6155 15.5 11.4793V4.58738C15.5 3.45112 15.2083 2.57979 14.6487 1.99339C14.0932 1.41137 13.2003 1.03333 11.8333 1.03333H5.16667C3.7997 1.03333 2.90676 1.41137 2.35129 1.99339ZM1.62787 1.30299C2.42657 0.466093 3.61697 0.0333252 5.16667 0.0333252H11.8333C13.383 0.0333252 14.5734 0.466093 15.3721 1.30299C16.1667 2.13551 16.5 3.2912 16.5 4.58738V11.4793C16.5 12.7754 16.1667 13.9311 15.3721 14.7637C14.5734 15.6006 13.383 16.0333 11.8333 16.0333H5.16667C3.61697 16.0333 2.42657 15.6006 1.62787 14.7637C0.833345 13.9311 0.5 12.7754 0.5 11.4793V4.58738C0.5 3.2912 0.833345 2.13551 1.62787 1.30299Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.0789 8.8036C11.0789 8.52746 11.3027 8.3036 11.5789 8.3036H11.5864C11.8625 8.3036 12.0864 8.52746 12.0864 8.8036C12.0864 9.07975 11.8625 9.3036 11.5864 9.3036H11.5789C11.3027 9.3036 11.0789 9.07975 11.0789 8.8036Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.0789 11.236C11.0789 10.9599 11.3027 10.736 11.5789 10.736H11.5864C11.8625 10.736 12.0864 10.9599 12.0864 11.236C12.0864 11.5122 11.8625 11.736 11.5864 11.736H11.5789C11.3027 11.736 11.0789 11.5122 11.0789 11.236Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.99619 8.8036C7.99619 8.52746 8.22004 8.3036 8.49619 8.3036H8.50367C8.77981 8.3036 9.00367 8.52746 9.00367 8.8036C9.00367 9.07975 8.77981 9.3036 8.50367 9.3036H8.49619C8.22004 9.3036 7.99619 9.07975 7.99619 8.8036Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.99619 11.236C7.99619 10.9599 8.22004 10.736 8.49619 10.736H8.50367C8.77981 10.736 9.00367 10.9599 9.00367 11.236C9.00367 11.5122 8.77981 11.736 8.50367 11.736H8.49619C8.22004 11.736 7.99619 11.5122 7.99619 11.236Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.91196 8.8036C4.91196 8.52746 5.13581 8.3036 5.41196 8.3036H5.41944C5.69558 8.3036 5.91944 8.52746 5.91944 8.8036C5.91944 9.07975 5.69558 9.3036 5.41944 9.3036H5.41196C5.13581 9.3036 4.91196 9.07975 4.91196 8.8036Z"
              fill="#CFD1D6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.91196 11.236C4.91196 10.9599 5.13581 10.736 5.41196 10.736H5.41944C5.69558 10.736 5.91944 10.9599 5.91944 11.236C5.91944 11.5122 5.69558 11.736 5.41944 11.736H5.41196C5.13581 11.736 4.91196 11.5122 4.91196 11.236Z"
              fill="#CFD1D6"
            />
          </svg>
        </div>
        <div class="p-4 self-baseline" @click="dropdown = 'phones'">
          <svg width="20" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.2765 12.4036C14.2765 12.648 14.2222 12.8991 14.1068 13.1434C13.9914 13.3877 13.8421 13.6185 13.6453 13.8356C13.3128 14.2021 12.9463 14.4668 12.5323 14.6365C12.1251 14.8061 11.684 14.8944 11.2089 14.8944C10.5167 14.8944 9.77693 14.7315 8.99646 14.3989C8.21599 14.0664 7.43553 13.6185 6.66185 13.0552C5.88138 12.4851 5.14163 11.8539 4.43582 11.1549C3.73679 10.4491 3.10563 9.70934 2.54234 8.93566C1.98583 8.16198 1.53791 7.3883 1.21215 6.6214C0.886391 5.84772 0.723511 5.10798 0.723511 4.40216C0.723511 3.94067 0.804951 3.49954 0.967831 3.09234C1.13071 2.67835 1.3886 2.29829 1.7483 1.95896C2.18264 1.5314 2.65771 1.32101 3.15993 1.32101C3.34995 1.32101 3.53998 1.36173 3.70965 1.44317C3.8861 1.52461 4.04219 1.64677 4.16435 1.82323L5.73886 4.04247C5.86102 4.21214 5.94925 4.36823 6.01033 4.51754C6.07141 4.66006 6.10534 4.80258 6.10534 4.93152C6.10534 5.0944 6.05783 5.25728 5.96282 5.41338C5.87459 5.56947 5.74565 5.73235 5.58277 5.89523L5.06698 6.43138C4.99233 6.50603 4.95839 6.59426 4.95839 6.70284C4.95839 6.75714 4.96518 6.80464 4.97875 6.85894C4.99911 6.91323 5.01947 6.95395 5.03305 6.99467C5.15521 7.21863 5.36559 7.51046 5.66421 7.86336C5.96961 8.21627 6.29537 8.57596 6.64827 8.93566C7.01475 9.29535 7.36766 9.6279 7.72735 9.9333C8.08026 10.2319 8.37209 10.4355 8.60283 10.5577C8.63677 10.5712 8.67749 10.5916 8.72499 10.612C8.77929 10.6323 8.83358 10.6391 8.89466 10.6391C9.01003 10.6391 9.09826 10.5984 9.17291 10.5237L9.6887 10.0147C9.85837 9.84507 10.0212 9.71612 10.1773 9.63468C10.3334 9.53967 10.4895 9.49216 10.6592 9.49216C10.7881 9.49216 10.9239 9.51931 11.0732 9.58039C11.2225 9.64147 11.3786 9.7297 11.5482 9.84507L13.7946 11.4399C13.9711 11.5621 14.0932 11.7046 14.1679 11.8743C14.2358 12.044 14.2765 12.2136 14.2765 12.4036Z"
              stroke="#CFD1D6"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </div>
    </div>
    <Dropdown
      v-if="dropdown === 'phones'"
      :get-item-attr="getPhoneItemsAttrs"
      :items="phoneItems"
      :get-ref-root="() => $refs.dropdownRoot"
      :offset="20"
      :dropdown-auto-width="true"
      vertical-direction="up"
      item-is="a"
      @close="close('phones')"
    />
    <Dropdown
      v-if="dropdown === 'timetable'"
      :items="timetableItems"
      :get-ref-root="() => $refs.dropdownRoot"
      :offset="20"
      :dropdown-auto-width="true"
      label="Расписание в клубах:"
      vertical-direction="up"
      @close="close('timetable')"
      @select="close(dropdown)"
    />
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import Dropdown from '../../atoms/Dropdown'
import MoleculeNavMobileListLink from '../../molecules/layouts/MoleculeNavMobileListLink'

export default {
  name: 'OrganismNavMobile',
  components: {
    AtomButton,
    MoleculeNavMobileListLink,
    Dropdown,
  },
  props: ['menu', 'mobileHeadLink'],
  data: () => {
    return {
      showMenu: false,
      dropdown: null,
    }
  },
  computed: {
    phoneItems() {
      return this.$store.getters['modules/content/getClubsAsListPhones']
    },
    timetableItems() {
      return this.$store.getters['modules/content/getClubsAsListTimetableDropdownMenu']
    },
  },
  watch: {
    $route() {
      this.showMenu = false
    },
    showMenu() {
      if (this.showMenu) {
        this.$store.dispatch('scrollBlocker/block', 'menu')
      } else {
        this.$store.dispatch('scrollBlocker/unblock', 'menu')
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('scrollBlocker/unblock', 'menu')
  },
  methods: {
    getPhoneItemsAttrs(item) {
      return {
        href: item.tel,
      }
    },
    close(type) {
      if (this.dropdown === type) this.dropdown = null
    },
    toTimetable(item) {
      if (item && item.to) this.$router.push(item.to)
    },
  },
}
</script>

<style lang="postcss">
.nav-mobile {
  @apply opacity-0;
  transform: translateY(-100%);
  transition: opacity 0.4s var(--cubic-bezier);
  &.open {
    transform: translateY(0%);
    @apply opacity-100;
  }
  .accordion__item {
    @apply !border-gray-300;
  }
}
.burger-mobile {
  width: 52px;
  height: 42px;
  padding: 16px 12px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    @apply bg-gray-500;
    display: block;
    position: absolute;
    height: 1px;
    opacity: 1;
    left: 18px;
    right: 18px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 14px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 20px;
    }
    &:nth-child(4) {
      top: 26px;
    }
  }
  &.open {
    span {
      left: 15px;
      right: 15px;
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
</style>
