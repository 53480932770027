const defaults = {
  global: {},
  clubs: [],
}

const content = {
  global: {},
  clubs: [],
}

export const state = () => ({
  locale: 'ru',
  pageId: null,
  ru: { ...content },
  en: { ...content },
})

function groupClubs(clubs, getLinkTo, filterPredicate = () => true) {
  const groupped = clubs.reduce((acc, club) => {
    if (!club.city || !filterPredicate(club)) return acc
    if (!acc[club.city.id]) acc[club.city.id] = []
    acc[club.city.id].push(club)
    return acc
  }, {})

  const grouppedMenu = Object.keys(groupped).reduce((acc, group) => {
    acc.push({
      title: groupped[group][0].city.title,
      cityId: groupped[group][0].city.id,
      links: groupped[group].map(club => ({
        title: club.title,
        to: getLinkTo(club),
      })),
    })
    return acc
  }, [])
  return grouppedMenu
}

function listClubs(clubs, getLinkTo, filterPredicate = () => true) {
  return clubs.filter(filterPredicate).map(club => ({
    title: club.title,
    to: getLinkTo(club),
  }))
}

export const getters = {
  global(state) {
    return state[state.locale].global
  },
  page(state) {
    return state.pageId ? state[state.locale][state.pageId] : null
  },
  trainers(state) {
    return club => state[state.locale][`fitbase/${club}/trainers`] || []
  },
  clubs(state, getters) {
    const sortByGeo = getters.geoposition.id ? club => (club.city.id === getters.geoposition.id ? -1 : 1) : () => 0
    const clubs = (state[state.locale].clubs || []).slice(0).sort(sortByGeo)
    return clubs
  },
  geoposition(state) {
    return state[state.locale]['city/ip'] || {}
  },
  geopositionClub(state, getters) {
    const clubs = getters.clubs
    const {id: cityId} = getters.geoposition
    return clubs.filter(({city}) => city.id === cityId)[0] || clubs[0]
  },
  clubsWithSpa(state, getters) {
    return getters.clubs.filter(c => c.spa)
  },
  getWriteUsSubjects(state, getters) {
    const {emailFeedbackMessage = []} = getters.global || {}
    return emailFeedbackMessage.map(({title, ...rest}) => ({text: title, ...rest}))
  },
  getClubsTownsAsDropdownWithAll(_, getters) {
    return [
      {
        text: 'Все города',
        sup: ('00' + getters.clubs.length).slice(-2),
        cityId: 'all',
      },
    ].concat(getters.getClubsTownsAsDropdown)
  },
  getClubsTownsAsDropdown(_, getters) {
    return groupClubs(getters.clubs, () => '').map(t => {
      return {
        text: t.title,
        sup: ('00' + t.links.length).slice(-2),
        cityId: t.cityId,
      }
    })
  },
  getClubsTownsAsSpaDropdownWithAll(_, getters) {
    const spaClubs = groupClubs(
      getters.clubs,
      () => '',
      c => c.spa,
    )
    const dropdownSpaClubs = spaClubs.map(t => {
      return {
        text: t.title,
        sup: ('00' + t.links.length).slice(-2),
        cityId: t.cityId,
      }
    })
    return [
      {
        text: 'Все города',
        sup: ('00' + spaClubs.reduce((acc, c) => acc + c.links.length, 0)).slice(-2),
        cityId: 'all',
      },
    ].concat(dropdownSpaClubs)
  },
  getClubsAsGrouppedClubsMenu(_, getters) {
    return groupClubs(getters.clubs, club => `/clubs/${club.slug}`)
  },
  getClubsAsGrouppedTimetableMenu(_, getters) {
    return groupClubs(getters.clubs, club => `/timetable/${club.slug}/adult`)
  },
  getClubsAsGrouppedCabinetMenu(_, getters) {
    return groupClubs(
      getters.clubs,
      club => club.cabinet,
      club => club.open,
    )
  },
  getClubsAsListClubsMenu(_, getters) {
    return listClubs(getters.clubs, club => `/clubs/${club.slug}`)
  },
  getClubsAsListTimetableMenu(_, getters) {
    return listClubs(getters.clubs, club => `/timetable/${club.slug}/adult`)
  },
  getClubsAsListCabinetMenu(_, getters) {
    return listClubs(
      getters.clubs,
      club => club.cabinet,
      club => club.open,
    ).map(a => ({
      title: a.title,
      text: a.title,
      href: a.to
    }))
  },
  getClubsAsListSpaMenu(_, getters) {
    return listClubs(
      getters.clubs,
      club => `/spa/${club.slug}`,
      club => club.spa,
    )
  },
  getClubsAsListForm(_, getters) {
    return getters.clubs.map(c => ({
      text: c.title,
      slug: c.slug,
      cityId: c.city?.id
    }))
  },
  getClubsAsListPhones(_, getters) {
    return listClubs(
      getters.clubs,
      club => `tel:${club.phone}`,
      club => Boolean(club.phone),
    ).map(({title, to}) => ({tel: to, text: title}))
  },
  getClubsAsListTimetableDropdownMenu(_, getters) {
    return listClubs(getters.clubs, club => `/timetable/${club.slug}/adult`)
      .map(a => ({text: a.title, to: a.to}))
  },
  getClubsAsListCabinetDropdownMenu(_, getters) {
    return listClubs(
      getters.clubs,
      club => club.cabinet,
      club => club.open,
    ).map(a => ({text: a.title, href: a.to}))
  },
}

export const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale
  },
  SET_ITEM(state, { data, id }) {
    state[this.$i18n.locale][id] = data
  },
  CLEAR_ITEM(state, id) {
    state[state.locale][id] = defaults[id] || {}
  },
  SET_PAGE(state, id) {
    state.pageId = id
  },
}

export const actions = {
  async setLocale({ commit, dispatch }, locale) {
    locale = locale || this.$i18n.locale || 'ru'
    this.$i18n.setLocale(locale)
    this.$i18n.setLocaleCookie(locale)

    commit('SET_LOCALE', locale)

    await Promise.all([
      dispatch('modules/content/getContent', { id: 'global' }, { root: true }),
      dispatch('modules/content/getContent', { id: 'clubs' }, { root: true }),
    ])
  },
  async getContent({ commit }, { id, headers }) {
    try {
      const response = await this.$axios({
        url: `/api/${id}`,
        method: 'get',
        headers: headers || {}
      })
      const data = response.data || defaults[id] || {}

      commit('SET_ITEM', { data, id })
      return data
    } catch (e) {
      commit('CLEAR_ITEM', id)
      return null
    }
  },
  saveCustomPageItem({ commit }, { data, id }) {
    commit('SET_ITEM', { data, id })
  },
  setPage({ commit }, id) {
    commit('SET_PAGE', id)
  },
}
