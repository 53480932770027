var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mobileOnly && !_vm.isMobile)?_c('div',[_vm._t("default")],2):_c('div',{ref:"holder",staticClass:"relative overflow-hidden",class:{ 'pb-[4px]': _vm.axis === 'x', 'pr-[2px]': _vm.axis === 'y', [_vm.theme]: true }},[_c('div',{ref:"inner",class:{
      'mb-[-20px] pb-5 overflow-x-scroll': _vm.axis === 'x',
      'mr-[-30px] overflow-y-scroll pr-8': _vm.axis === 'y',
      'xl:pr-10': _vm.axis === 'y' && !_vm.hiddenTrack,
    },on:{"!scroll":function($event){return _vm.handleScroll.apply(null, arguments)},"mouseover":function($event){return _vm.blockLocoScroll(true)},"mouseleave":function($event){return _vm.blockLocoScroll(false)},"touchstart":function($event){return _vm.blockLocoScroll(true)},"touchcancel":function($event){return _vm.blockLocoScroll(false)},"touchend":function($event){return _vm.blockLocoScroll(false)}}},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"absolute",class:{
      'inset-x-0 bottom-1': _vm.axis === 'x',
      'inset-y-0 right-0 flex scroll-bar': _vm.axis === 'y',
      hidden: _vm.hiddenTrack,
    }},[_c('div',{staticClass:"track",class:{
        'h-[3px] bg-repeat-x': _vm.axis === 'x',
        'w-[3px] bg-repeat-y': _vm.axis === 'y',
      }}),_vm._v(" "),_c('div',{ref:"thumb",staticClass:"rounded-full",class:{
        'h-[3px] mt-[-4px]': _vm.axis === 'x',
        'w-[3px] ml-[-4px]': _vm.axis === 'y',
        'bg-gray-100': _vm.theme === 'white',
        'bg-gray-500': _vm.theme === 'dark',
      }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }