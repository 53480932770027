<template>
  <section class="md:border-l border-l-gray-300 bg-gray-100">
    <div class="container">
      <div
        class="grid grid-cols-2 md:grid-cols-6 gap-y-9 gap-x-3 md:gap-3 border-b bg-gray-100 pt-5 pb-10 md:pt-10 md:pb-12"
      >
        <MoleculeFooterListLink :links="linksCol1" />
        <div>
          <MoleculeFooterListLink :links="linksCol2" />
          <AtomButton
            ref="timetableDesktopRoot"
            class="!hidden md:!inline-flex mt-20 ml-[-20px] btn-sm btn-white whitespace-nowrap fade-item"
            @click="dropdown = 'timetable-desktop'"
          >
            {{ training }}
            <svg class="ml-2" width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.56412 0.757812L6.5001 6.68099L11.4361 0.757813L12.2043 1.398L6.5001 8.24304L0.795898 1.398L1.56412 0.757812Z"
                fill="white"
              />
            </svg>
          </AtomButton>
        </div>
        <MoleculeFooterListLink
          class="col-span-2 grid grid-cols-2 md:flex gap-x-3 md:flex-col md:col-span-1"
          :links="linksCol3"
        />
        <MoleculeFooterListLink :links="linksCol4" />
        <MoleculeFooterListLink :links="linksCol5" />
        <div
          class="-md:gap-x-3 order-first justify-between col-span-2 md:order-none md:col-span-1 flex md:justify-end self-start"
        >
          <!-- <AtomButton
            ref="cabinetRoot"
            @click="dropdown = 'cabinet'"
            class="-md:flex-1 md:ml-[-20px] btn-sm btn-white whitespace-nowrap fade-item">
            {{ lk }}
            <svg class="ml-2" width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.56412 0.757812L6.5001 6.68099L11.4361 0.757813L12.2043 1.398L6.5001 8.24304L0.795898 1.398L1.56412 0.757812Z" fill="white"/>
            </svg>
          </AtomButton> -->
          <AtomButton
            ref="timetableMobileRoot"
            class="-md:flex-1 md:!hidden btn-sm btn-white whitespace-nowrap fade-item"
            @click="dropdown = 'timetable-mobile'"
          >
            {{ trainingMobile }}
            <svg class="ml-2" width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.56412 0.757812L6.5001 6.68099L11.4361 0.757813L12.2043 1.398L6.5001 8.24304L0.795898 1.398L1.56412 0.757812Z"
                fill="white"
              />
            </svg>
          </AtomButton>
          <!--          <AtomButton tag="a" class="md:!hidden btn-sm btn-white whitespace-nowrap fade-item">{{ lang }}</AtomButton>-->
        </div>
      </div>
    </div>
    <Dropdown
      v-if="dropdown === 'timetable-mobile'"
      :items="timetableItems"
      :get-ref-root="() => $refs.timetableMobileRoot.$el"
      :dropdown-auto-width="true"
      :offset="10"
      vertical-direction="down"
      @close="close('timetable-mobile')"
      @select="close(dropdown)"
    />
    <Dropdown
      v-if="dropdown === 'cabinet'"
      :items="cabinetItems"
      :get-ref-root="() => $refs.cabinetRoot.$el"
      :dropdown-auto-width="true"
      :offset="10"
      :horizontal-direction="'right'"
      vertical-direction="down"
      @close="close('cabinet')"
      @select="close(dropdown)"
    />
    <Dropdown
      v-if="dropdown === 'timetable-desktop'"
      :items="timetableItems"
      :get-ref-root="() => $refs.timetableDesktopRoot.$el"
      :dropdown-auto-width="true"
      :offset="10"
      vertical-direction="down"
      @close="close('timetable-desktop')"
      @select="close(dropdown)"
    />
  </section>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import Dropdown from '../../atoms/Dropdown'
import MoleculeFooterListLink from '../../molecules/layouts/MoleculeFooterListLink'

export default {
  name: 'OrganismFooterNav',
  components: {
    AtomButton,
    MoleculeFooterListLink,
    Dropdown,
  },
  props: ['linksCol1', 'linksCol2', 'linksCol3', 'linksCol4', 'linksCol5', 'training', 'lk', 'trainingMobile', 'lang'],
  data() {
    return {
      dropdown: null,
      isMobile: false,
    }
  },
  computed: {
    timetableItems() {
      return this.$store.getters['modules/content/getClubsAsListTimetableDropdownMenu']
    },
    cabinetItems() {
      return this.$store.getters['modules/content/getClubsAsListCabinetDropdownMenu']
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 960
  },
  methods: {
    toTimetable(item) {
      if (item && item.to) this.$router.push(item.to)
    },
    close(type) {
      if (this.dropdown === type) this.dropdown = null
    },
  },
}
</script>

<style lang="postcss">
.open {
  .fade-item {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-timing-function: var(--cubic-bezier);
    animation-fill-mode: forwards;
  }
  .fade-form {
    animation-name: fadeY;
    animation-duration: 0.6s;
    animation-timing-function: var(--cubic-bezier);
    animation-fill-mode: forwards;
  }
}
.menu {
  .fade-form {
    transform: translateY(20%);
    opacity: 0;
    animation-delay: 0.4s;
    will-change: transform;
  }
  .fade-item {
    transform: translateY(20%);
    opacity: 0;
    animation-delay: 0.4s;
    will-change: transform;
  }
  .fade-item + .fade-item {
    animation-delay: 0.5s;
  }
  .fade-item + .fade-item + .fade-item {
    animation-delay: 0.6s;
  }
  .fade-item + .fade-item + .fade-item + .fade-item {
    animation-delay: 0.7s;
  }
  .fade-item + .fade-item + .fade-item + .fade-item + .fade-item {
    animation-delay: 0.8s;
  }
  .fade-item + .fade-item + .fade-item + .fade-item + .fade-item + .fade-item {
    animation-delay: 1s;
  }
  .fade-item + .fade-item + .fade-item + .fade-item + .fade-item + .fade-item + .fade-item {
    animation-delay: 1.2s;
  }
  .fade-item + .fade-item + .fade-item + .fade-item + .fade-item + .fade-item + .fade-item + .fade-item {
    animation-delay: 1.4s;
  }
}

.animation-delay-400 {
  animation-delay: 0.4s;
}
.animation-delay-500 {
  animation-delay: 0.5s;
}
.animation-delay-600 {
  animation-delay: 0.6s;
}
.animation-delay-700 {
  animation-delay: 0.7s;
}
.animation-delay-800 {
  animation-delay: 0.8s;
}

@keyframes fade {
  from {
    transform: translate(0, 120%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeY {
  from {
    transform: translate(0, 40%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
