<template>
  <NuxtLink class="link inline-flex relative overflow-hidden" :to="to" :target="target">
    <span class="relative overflow-hidden">
      <span class="span">{{ content }}</span>
      <span class="span">{{ content }}</span>
    </span>
  </NuxtLink>
</template>

<script>
export default {
  name: 'AtomLink',
  props: ['content', 'to', 'target'],
}
</script>

<style lang="postcss" scoped>
.link {
  &:hover {
    .span:nth-of-type(1) {
      transform: translateY(-180%) rotate(-10deg);
      transition-delay: 0s;
    }
    .span:nth-of-type(2) {
      transform: translateY(0%) rotate(0.001deg);
      transition-delay: -0.05s;
    }
    &:not(.dark) {
      .span:nth-of-type(2) {
        @apply text-gray-400;
      }
    }
  }
  &.dark {
    .span {
      @apply !text-gray-500 hover:text-white;
      &:nth-of-type(2) {
        @apply !text-white;
      }
    }
    &.disabled span {
      @apply !text-gray-300;
    }
  }
  &.disabled span {
    @apply !text-gray-500;
    transform: none !important;
  }
  .span {
    @apply block text-base font-normal;
    &:nth-of-type(1) {
      transform: translateY(0%) rotate(0.001deg);
      transition: var(--animation-primary);
      /* white-space: nowrap; */
      transition-delay: -0.05s;
      position: relative;
    }
    &:nth-of-type(2) {
      @apply absolute top-0 left-0;
      /* white-space: nowrap; */
      transform: translateY(180%) rotate(10deg);
      transition: var(--animation-primary);
      transition-delay: 0s;
    }
  }
  &.sidebar-link {
    @apply text-gray-500;
    &.active {
      @apply text-gray-100;
    }
  }
  &:not(.sidebar-link) {
    .span {
      @apply text-base md:text-lg text-gray-100 hover:text-gray-400;
    }
  }
}
</style>
