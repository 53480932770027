<template>
  <label
    :for="idFor"
    :class="{ [`${theme || 'white'}`]: true }"
    class="placeholder absolute pointer-events-none text-sm md:text-base top-[17px] text-gray-100"
  >
    {{ label }}
  </label>
</template>

<script>
export default {
  name: 'AtomInputLabel',
  props: ['idFor', 'label', 'theme'],
}
</script>

<style lang="postcss" scoped>
.placeholder {
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  &.dark {
    @apply text-gray-500 hover:text-white active:text-white;
  }
  &.white {
    @apply text-gray-100;
  }
}
</style>
