<template>
  <nuxt-img
    v-if="source"
    :provider="provider"
    :modifiers="modifiers"
    :src="source"
    :width="width"
    :height="height"
    :preset="preset"
  />
</template>

<script>
export default {
  name: 'AtomImage',
  props: ['src', 'width', 'height', 'preset', 'strapiImageObject', 'strapiPreferOriginal'],
  computed: {
    provider() {
      return this.handleAsStrapi ? 'img-strapi' : 'ipx'
    },
    handleAsStrapi() {
      return (
        typeof this.strapiImageObject === 'object' &&
        this.strapiImageObject !== null &&
        Boolean(this.strapiImageObject.url)
      )
    },
    source() {
      if (this.handleAsStrapi) {
        return this.strapiImageObject.url
      }
      return this.src
    },
    modifiers() {
      if (!this.handleAsStrapi) return {}
      if (this.strapiPreferOriginal !== false) return null
      const getExisted = prefered => {
        if (this.strapiImageObject && this.strapiImageObject.formats) {
          const breakpoints = ['large', 'medium', 'small']
          for (let i = breakpoints.indexOf(prefered); i < breakpoints.length; i++) {
            const breakpoint = breakpoints[i]
            if (this.strapiImageObject.formats[breakpoint]) return breakpoint
          }
        }
      }
      if (this.$device.isDesktop) {
        return { breakpoint: getExisted('large') }
      } else if (this.$device.isTablet) {
        return { breakpoint: getExisted('medium') }
      }
      return { breakpoint: getExisted('medium') }
      // return { breakpoint: getExisted('small') }
    },
  },
}
</script>
