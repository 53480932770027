<template>
  <section
    ref="nav"
    :class="{
      ['nav-fixed']: isFixed,
      ['nav-sticky z-40']: !isHeroVisible && !noStickyHeader,
      ['!z-20']: noStickyHeader && !isHeroVisible,
    }"
    class="nav absolute top-0 left-[75px] right-0 z-40 overflow-x-clip"
    :style="offsetTop ? `transform: translateY(-${offsetTop}px)` : ''"
  >
    <div ref="containerNav" class="container container-nav" :class="{ 'hover-nav': hoverNav }">
      <div
        class="sub-nav-bg absolute top-0 left-0 w-full"
        :style="`height: ${hoverNav ? height : 0}px`"
        aria-hidden="true"
      />
      <div class="flex justify-between md:-mx-5 overflow-y-visible relative container-nav-list">
        <div
          class="flex menu-item-wrap md:pl-4 lg:pl-0 z-90"
          @mouseover="hoverNav = true"
          @mouseleave="onUnhoveredMenu"
        >
          <MoleculeNavListLink v-for="nav in leftMenu" :key="nav.name" :nav="nav" @hovered="hoveredItem" />
        </div>
        <div
          class="flex menu-item-wrap md:pr-4 lg:pr-0 z-90"
          @mouseover="hoverNav = true"
          @mouseleave="onUnhoveredMenu"
        >
          <MoleculeNavListLink v-for="nav in rightMenu" :key="nav.name" :nav="nav" @hovered="hoveredItem" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MoleculeNavListLink from '../../molecules/layouts/MoleculeNavListLink'

export default {
  name: 'OrganismNav',
  components: {
    MoleculeNavListLink,
  },
  props: ['leftMenu', 'rightMenu', 'showNav'],
  data: () => {
    return {
      hoverNav: false,
      isFixed: false,
      isHeroVisible: false,
      noStickyHeader: false,
      offsetTop: 0,
      height: 0,
    }
  },
  watch: {
    '$nuxt.$route.path': function () {
      this.hoverNav = false
    },
  },
  mounted() {
    this.$root.$loco.on('scroll', this.handleScroll)
    const menuItem = document.querySelector('.about-menu').getBoundingClientRect().left - 75
    document.querySelector('.about-menu .sub-nav-link').style.left = menuItem + 'px'
  },
  beforeDestroy() {
    this.$root.$loco.off('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(data) {
      const { hero, no_sticky_header, footer_pin } = data.currentElements
      this.isHeroVisible = hero ? hero.progress < 0.99 : data.scroll.y < 50
      this.noStickyHeader = Boolean(no_sticky_header) || (Boolean(footer_pin) && footer_pin.progress > 0.36)
      if (this.isDesktop()) {
        this.isFixed = data.direction !== 'down' || this.isHeroVisible
        this.offsetTop = this.getTransform(data)
      } else {
        this.isFixed = false
      }
    },
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
    getTransform(data) {
      if (this.$route.name === 'home') {
        return 0
      }
      const box = this.$refs.nav.getBoundingClientRect()
      return Math.min(box.height, data.scroll.y)
    },
    hoveredItem(h, isSubMenu) {
      this.height = h + 70
      if (!isSubMenu) {
        this.hoverNav = false
      }
    },
    onUnhoveredMenu() {
      this.hoverNav = false
    },
  },
}
</script>

<style lang="postcss">
.menu-item-wrap {
  &:before {
    @apply bg-gray-100 md:left-5 md:right-5 h-[1px] top-[69px] absolute z-[15] opacity-0 pointer-events-none;
  }
  &:hover {
    &:before {
      @apply opacity-100;
    }
    /* .sub-nav-bg {
      @apply !opacity-100;
    } */
  }
}
.sub-nav-bg {
  background-color: #fff;
  visibility: visible;
  opacity: 1;
  will-change: height;
  transform: translateZ(0);
  /* transition: height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
  transition: height 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.45s cubic-bezier(0.43, 0.195, 0.02, 1);
  pointer-events: none;
  will-change: height;
  z-index: 1;
  /* box-shadow: 0 5px 10px 0 rgb(27 34 92 / 5%); */
}
.hover-nav {
  &:before {
    @apply opacity-100;
  }
  .menu-item-wrap {
    &:before {
      @apply content-[''];
    }
  }
}
.nav-sticky {
  transform: translateY(-100%);
  transition: transform 0.4s var(--cubic-bezier);
}
/* .container-nav {
  clip: rect(auto, 100vw, 100vh, auto);
} */
.nav-fixed.nav-sticky {
  @apply !overflow-x-visible;
  transform: translateY(0%) !important;
  /* box-shadow: 0 5px 10px 0 rgb(27 34 92 / 5%); */
  &:after {
    @apply content-[''] bg-white top-0 w-full absolute;
    height: calc(100% - 1px);
  }
  /* &:before {
    @apply content-[''] bg-gray-100 left-5 right-5 h-[1px] bottom-0 absolute z-10;
  } */
  .menu-item-wrap:before {
    @apply content-[''] bg-gray-100 opacity-100;
  }
  .link {
    @apply text-gray-100;
    .wrap-text {
      @apply !border-gray-100;
    }
  }
  .link-sub {
    svg {
      path {
        @apply stroke-gray-100;
      }
    }
  }
  .hover-nav {
    &:hover {
      .menu-item-wrap:before {
        @apply bg-gray-500;
      }
    }
  }
  /* .sub-link {
    &:hover {
      .link-sub {
        svg {
          @apply rotate-180;
          path {
            @apply stroke-gray-100;
          }
        }
      }
      a {
        @apply text-gray-100;
        .wrap-text {
          @apply !border-white;
        }
        .box-text {
          &:before {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
      .sub-nav-wrap {
        visibility: visible;
        transition-delay: 0s;
        z-index: 3;
        pointer-events: auto;
        .sub-nav-bg {
          opacity: 1;
          transform: translateY(0);
        }
        .sub-nav-link {
          transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
            transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
  } */
}
</style>
