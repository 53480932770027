<template>
  <OrganismFooterNav
    :links-col1="col1"
    :links-col2="col2"
    :links-col3="col3"
    :links-col4="col4"
    :links-col5="col5"
    :training="training"
    :training-mobile="trainingMobile"
    :lk="lk"
    lang="en"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import OrganismFooterNav from '../../organisms/layouts/OrganismFooterNav'

export default {
  name: 'TemplateFooterNav',
  components: {
    OrganismFooterNav,
  },
  data: () => {
    return {
      training: 'расписание тренировок',
      trainingMobile: 'расписание',
      lk: 'личный кабинет',
      // lang: 'en',
    }
  },
  computed: {
    ...mapGetters({
      listClubsForClubs: 'modules/content/getClubsAsListClubsMenu',
    }),
    col1() {
      return [
        {
          to: '/clubs',
          title: 'Все клубы',
        },
        ...this.listClubsForClubs,
      ]
    },
    col2() {
      return [
        // {
        //   to: '/fitness/personal-training',
        //   title: 'Персональный тренинг',
        // },
        // {
        //   to: '/fitness/group-training',
        //   title: 'Групповой тренинг',
        // },
        // {
        //   to: '/fitness/mini-group',
        //   title: 'Мини-группы',
        // },
        // {
        //   to: '/fitness/kids-clubs',
        //   title: 'Детский тренинг',
        // },
        {
          to: '/fitness/testing',
          title: 'Фитнес-тестирование',
        },
        {
          to: '/fitness/myzone',
          title: 'MYZONE®',
        },
      ]
    },
    col3() {
      return [
        // {
        //   to: '/team',
        //   title: 'Тренеры',
        //   class: 'md:mb-6',
        // },
        {
          to: '/spa',
          title: 'Spa-комплексы',
        },
      ]
    },
    col4() {
      return [
        {
          to: '/about',
          title: 'О нас',
        },
        {
          to: '/franchise',
          title: 'Франшиза',
        },
        {
          to: '/career',
          title: 'Карьера',
        },
        {
          to: '/contacts',
          title: 'Контакты',
        },
      ]
    },
    col5() {
      return [
        {
          to: 'https://t.me/encorerus',
          title: 'Telegram',
        },
        {
          to: 'https://instagram.com/encorerus?igshid=MzRlODBiNWFlZA==',
          title: 'Instagram',
        },
        {
          to: 'https://vk.com/encorefitness',
          title: 'VK',
        },
      ]
    },
  },
}
</script>
