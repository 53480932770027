<template>
  <Portal to="backdrop">
    <transition :duration="{ enter: 300, leave: 300 }" name="backdrop" appear>
      <div
        class="fixed w-full h-full inset-0 bg-gray-100 opacity-60"
        @click="$emit('close')"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      />
    </transition>
  </Portal>
</template>

<script>
export default {
  name: 'AtomBackdrop',
  data() {
    return {
      touchStart: {},
      touchEnd: {},
    }
  },
  computed: {
    touched() {
      const xDiff = Math.abs(this.touchStart.clientX - this.touchEnd.clientX)
      const yDiff = Math.abs(this.touchStart.clientY - this.touchEnd.clientY)
      if (isNaN(xDiff) || isNaN(yDiff)) return true
      return xDiff + yDiff < 50
    },
  },
  mounted() {
    document.addEventListener('keydown', this.preventFocus, true)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.preventFocus, true)
  },
  methods: {
    preventFocus(e) {
      if (e.keyCode === 9) {
        e.preventDefault()
      }
    },
    handleTouchStart(e) {
      this.preventScrollIOS(e)
      const [touch = {}] = e.touches || []
      this.touchStart = touch
    },
    handleTouchEnd() {
      if (this.touched) this.$emit('close')
    },
    handleTouchMove(e) {
      const [touch = {}] = e.touches || []
      this.touchEnd = touch
    },
    preventScrollIOS(e) {
      e.preventDefault()
    },
  },
}
</script>

<style lang="postcss" scoped>
div.backdrop-enter,
div.backdrop-leave-to {
  @apply opacity-0;
}
div.backdrop-enter-to,
div.backdrop-leave {
  @apply opacity-60;
}
div.backdrop-enter-active,
div.backdrop-leave-active {
  transition: opacity 0.2s var(--cubic-bezier);
}
</style>
