import { render, staticRenderFns } from "./MoleculeInputPhone.vue?vue&type=template&id=45faf2ac&scoped=true&"
import script from "./MoleculeInputPhone.vue?vue&type=script&lang=js&"
export * from "./MoleculeInputPhone.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeInputPhone.vue?vue&type=style&index=0&id=45faf2ac&prod&lang=postcss&scoped=true&"
import style1 from "./MoleculeInputPhone.vue?vue&type=style&index=1&id=45faf2ac&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45faf2ac",
  null
  
)

export default component.exports