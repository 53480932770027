<template>
  <a
    v-if="(to && to.startsWith('http')) || href"
    :href="href || to"
    target="_blank"
    class="link inline-flex relative text-gray-500 hover:text-white text-sm md:text-base overflow-hidden"
  >
    <span class="span" v-html="content" />
    <span class="span" v-html="content" />
  </a>
  <NuxtLink
    v-else-if="to"
    class="link inline-flex relative text-gray-500 hover:text-white text-sm md:text-base overflow-hidden"
    :to="to"
    :target="target"
  >
    <span class="span" v-html="content" />
    <span class="span" v-html="content" />
  </NuxtLink>
  <span v-else class="link inline-flex relative text-gray-500 text-sm md:text-base overflow-hidden" v-html="content" />
</template>

<script>
export default {
  name: 'AtomLinkItem',
  props: ['content', 'to', 'target', 'href'],
}
</script>

<style lang="postcss" scoped>
.link {
  &:hover {
    .span:nth-of-type(1) {
      transform: translateY(-220%) rotate(-10deg);
      transition-delay: 0s;
    }
    .span:nth-of-type(2) {
      transform: translateY(0%) rotate(0.001deg);
      transition-delay: -0.05s;
    }
  }
  &.disabled span {
    @apply !text-gray-500;
    transform: none !important;
  }
  &:not(.text-gray-400) {
    .span {
      @apply text-gray-500 hover:text-white;
    }
  }
}
.span {
  @apply block;
  &:nth-of-type(2) {
    @apply text-white;
  }
  &:nth-of-type(1) {
    transform: translateY(0%) rotate(0.001deg);
    transition: var(--animation-primary);
    white-space: nowrap;
    transition-delay: -0.05s;
    position: relative;
  }
  &:nth-of-type(2) {
    @apply absolute top-0 left-0;
    white-space: nowrap;
    transform: translateY(220%) rotate(10deg);
    transition: var(--animation-primary);
    transition-delay: 0s;
  }
}
</style>
